import {
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { useGetUsersAdminQuery } from "../../features/api/api";
import { User } from "../../types";

interface UsersTableProps  {
	onClick?: (user: User) => void;
}

export const UsersTable = ({onClick}:UsersTableProps) => {
	const { data, error, isLoading } = useGetUsersAdminQuery();
	const clickRow = (user: User) => {
		if( !onClick){
			return;
		}
		onClick(user)
	};
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}
	return (
			<TableContainer>
				<Table size='sm'>
					<Thead>
						<Tr>
							<Td fontWeight="extrabold">Namn</Td>
							<Td fontWeight="extrabold">Användarnamn</Td>
							<Td fontWeight="extrabold">epost</Td>
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((row) => (
							<Tr _hover={{
								bg: 'teal.400',
								color: 'white',
								cursor: 'pointer',
							}}
								onClick={() => clickRow(row)}
								key={row.id}
							>
								<Td>{row.name} </Td>
								<Td>{row.username} </Td>
								<Td>{row.email} </Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
	)
}
