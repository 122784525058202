import {
	Box,
	Center,
	Text,
	Stack,
	List,
	ListItem,
	ListIcon,
	SimpleGrid,
	Button,
	Input,
	useColorModeValue,
	Spinner,
	Alert,
	AlertIcon,
	Tooltip,
	Flex,
	Heading,
	Spacer,
	ButtonGroup,
	IconButton,
	Checkbox,
} from '@chakra-ui/react';
import { CheckCircleIcon, TimeIcon, WarningIcon } from '@chakra-ui/icons';
import { useGetConsumptionStatisticsQuery, useGetControllersQuery } from '../../features/api/api'
import { Controller } from '../../types';
import { Link as RouterLink } from 'react-router-dom';

import { format, formatRelative, formatRFC3339, isValid, parse, parseISO, sub } from 'date-fns'
import { sv } from 'date-fns/locale'
import { logout } from '../../features/user/userSlice'
import { useAppDispatch } from '../../app/hooks';
import { Graph } from './controller';
import { SetStateAction, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';

export interface CardProps {
	controller: Controller;
	selectedControllers: string[];
	setSelectedControllers(arg0: SetStateAction<string[]>): void
}

const Card = ({ controller: c, setSelectedControllers, selectedControllers }: CardProps) => {


	const online = c.online ? <ListItem><ListIcon as={CheckCircleIcon} color="green.400" />Online</ListItem> : <ListItem><ListIcon as={WarningIcon} color="red.400" />Offline</ListItem>
	const lastUpdate = parseISO(c.lastUpdate);

	return (
		<Box
			mx={10}
			my={10}
			maxW={'330px'}
			w={'full'}
			bg={useColorModeValue('white', 'gray.800')}
			boxShadow={'2xl'}
			rounded={'md'}
			overflow={'hidden'}>
			<Tooltip shouldWrapChildren={true} label='Visa förbrukningsstatistik' >
					<Checkbox
						pl={'10px'}
						pt={'10px'}
						size='lg'
						colorScheme='teal'
						onChange={(e) => {
							if (e.target.checked) {
								setSelectedControllers([...selectedControllers, c.id]);
								return;
							}
							setSelectedControllers(selectedControllers.filter((column) => {
								return column != c.id;
							}));
						}
						}
					/>
			</Tooltip>
			<Stack
				textAlign={'center'}
				p={6}
				color={useColorModeValue('gray.800', 'white')}
				align={'center'}>
				<Text
					fontSize={'sm'}
					fontWeight={500}
					bg={useColorModeValue('green.50', 'green.900')}
					p={2}
					px={3}
					color={'green.500'}
					rounded={'full'}>
					{c.location}
				</Text>
				<Stack direction={'row'} align={'center'} justify={'center'}>
					<Text fontSize={'3xl'}>{c.name}</Text>
				</Stack>
			</Stack>

			<Box bg={useColorModeValue('gray.50', 'gray.900')} px={6} py={10}>
				<List spacing={3}>
					{online}
					<ListItem><Tooltip label='Senast online' fontSize='md'><ListIcon as={TimeIcon} color="green.400" /></Tooltip>{formatRelative(lastUpdate, new Date(), { locale: sv })}</ListItem>
				</List>

				<Button
					mt={10}
					w={'full'}
					bg={'teal.400'}
					color={'white'}
					rounded={'lg'}
					as={RouterLink}
					to={'/inside/controller/' + c.id + '/status'}
					_hover={{
						bg: 'teal.500',
					}}
					_focus={{
						bg: 'teal.500',
					}}>
					Öppna
				</Button>
			</Box>
		</Box>
	);

}

const testdata = `{"data":[
	{"time":"2024-01-17T00:00:00+01:00","kwh":50.2,"cost":73.99,"avgPrice":0.87},
	{"time":"2024-01-18T00:00:00+01:00","kwh":58.2,"cost":42.99,"avgPrice":0.87},
	{"time":"2024-01-19T00:00:00+01:00","kwh":57.2,"cost":41.99,"avgPrice":0.87},
	{"time":"2024-01-20T00:00:00+01:00","kwh":59.2,"cost":43.99,"avgPrice":0.87},
	{"time":"2024-01-21T00:00:00+01:00","kwh":41.2,"cost":13.99,"avgPrice":0.87},
	{"time":"2024-01-22T00:00:00+01:00","kwh":41.2,"cost":41.99,"avgPrice":0.87},
	{"time":"2024-01-23T00:00:00+01:00","kwh":52.2,"cost":43.99,"avgPrice":0.87},
	{"time":"2024-01-24T00:00:00+01:00","kwh":58.2,"cost":13.99,"avgPrice":0.87},
	{"time":"2024-01-25T00:00:00+01:00","kwh":51.2,"cost":43.99,"avgPrice":0.87},
	{"time":"2024-01-26T00:00:00+01:00","kwh":53.6,"cost":29.5363,"avgPrice":0.55}]}`;

const translations: Record<string, any> = {
	'kwh': {
		k: 'Förbrukning',
		suffix: 'kWh',
		color: '#4FD1C3'
	},
	'avgPrice': {
		k: 'Medelelpris',
		suffix: 'kr',
		color: '#FFC760'
	},
	'price': {
		k: 'Elpris',
		suffix: 'kr',
		color: '#FFC760'
	},
	'cost': {
		k: 'Kostnad',
		suffix: 'kr',
		color: '#FC5F6A'
	},
}
interface StatisticsProps {
	selectedControllers: string[];
}

export const Statistics = ({ selectedControllers }: StatisticsProps) => {
	const now = new Date();
	const [groupby, setGroupby] = useState<string>("h");
	const [startFormatted, setStartFormatted] = useState<string>(format(sub(now, { hours: 24 }), 'yyyy-MM-dd'));
	const [stopFormatted, setStopFormatted] = useState<string>(format(now, 'yyyy-MM-dd'));
	const [start, setStartRFC] = useState<string>(formatRFC3339(sub(now, { hours: 24 })));
	const [stop, setStopRFC] = useState<string>(formatRFC3339(now));
	const skip = selectedControllers.length === 0
	const { data, error, isLoading, refetch } = useGetConsumptionStatisticsQuery({ position: 'heatpump_electric', groupby: groupby, stop: stop, start: start, controllerIds: selectedControllers, }, { skip });
	if (isLoading) {
		return <Spinner size='xl' />;
	}

	//TODO om vi inte hittade någon data ska vi presentera det i en vy samt att man borde skaffa en m-bus mätare?
	if (skip || !data || data?.data?.length === 0) {
		return null;
	}
	const graphData = data?.data?.map((item: any) => ({
		...item,
		// meanCost: (item.kwh * item.avgPrice) - item.cost, //TODO finish here
		// Besparing: (item.kwh * item.avgPrice) - item.cost //TODO finish here
	}));

	const setStart = (d: Date) => {
		setStartFormatted(format(d, 'yyyy-MM-dd'));
		setStartRFC(formatRFC3339(d)) // send RFC to backend
	}
	const setStop = (d: Date) => {
		setStopFormatted(format(d, 'yyyy-MM-dd'));
		setStopRFC(formatRFC3339(d)) // send RFC to backend
	}


	const setGroupBy = (by: string) => {
		setGroupby(by);
		const now = new Date();
		if (by == 'h') {
			setStart(sub(now, { hours: 24 }));
		}
		if (by == 'd') {
			setStart(sub(now, { months: 1 }));
		}
		if (by == 'm') {
			setStart(sub(now, { years: 1 }));
		}
		if (by == 'y') {
			setStart(sub(now, { years: 2 }));
		}
		setStop(now);
	}


	return (
		<div>
			<Flex p={'10px'} minWidth='max-content' alignItems='center' gap='2'>
				<Box p='2'>
					<Heading size='sm'>Förbruknings och kostnads statistik</Heading>
				</Box>
				<Spacer />
				<Input width='150px' value={startFormatted} onChange={e => {
					setStartFormatted(e.target.value);
				}} onBlur={(e) => {
					const start = parse(e.target.value, 'yyyy-MM-dd', new Date())
					if (isValid(start)) {
						setStartRFC(formatRFC3339(start)) // do search on blur
					}
				}}
				/>
				<Input width='150px' value={stopFormatted} onChange={e => {
					setStopFormatted(e.target.value);
				}} onBlur={e => {
					const stop = parse(e.target.value, 'yyyy-MM-dd', new Date())
					if (isValid(stop)) {
						setStopRFC(formatRFC3339(stop)) // do search on blur
					}
				}} />
				<ButtonGroup gap='2'>
					<Button onClick={() => setGroupBy("y")} bg={groupby == 'y' ? 'gray.300' : 'gray.100'} >År</Button>
					<Button onClick={() => setGroupBy("m")} bg={groupby == 'm' ? 'gray.300' : 'gray.100'} >Månad</Button>
					<Button onClick={() => setGroupBy("d")} bg={groupby == 'd' ? 'gray.300' : 'gray.100'} >Dag</Button>
					<Button onClick={() => setGroupBy("h")} bg={groupby == 'h' ? 'gray.300' : 'gray.100'} >Timme</Button>
					<IconButton
						variant="outline"
						onClick={refetch}
						aria-label="open menu"
						icon={<FiRefreshCw />}
					/>
				</ButtonGroup>
			</Flex>
			<Graph chartType='bar'
				translations={translations}
				YtimeFormat={groupByToTimeFormat(groupby)}
				style={{ userSelect: 'none', height: "300px", width: '100%', padding: '10px' }}
				data={graphData}
				setStart={setStart}
				setStop={setStop} />
		</div>);
}

const groupByToTimeFormat = (by: string): string => {
	if (by == 'h') {
		return "yyyy-MM-dd HH:mm:ss"
	}
	if (by == 'd') {
		return "yyyy-MM-dd"
	}
	if (by == 'm') {
		return "yyyy-MM"
	}
	if (by == 'y') {
		return "yyyy"
	}
	return "yyyy-MM-dd"
}

export const InsideHomePage = () => {
	const [selectedControllers, setSelectedControllers] = useState<string[]>([]);
	const { data, error, isLoading } = useGetControllersQuery();
	const dispatch = useAppDispatch();
	//TODO error?
	if (isLoading) {
		return <Spinner size='xl' />;
	}
	if (error && 'status' in error && error.status === 401) {
		console.log("error", error);
		dispatch(logout());
		return (
			<Alert status='error'>
				<AlertIcon />
				There was an error processing your request
			</Alert>
		);
	}
	return (
		<div>
			<div>
				<Statistics selectedControllers={selectedControllers} />
			</div>

			<SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
				{data?.map((controller) => (
					<Card key={controller.id} controller={controller} selectedControllers={selectedControllers} setSelectedControllers={setSelectedControllers} />
				))}
			</SimpleGrid>
		</div>
	);
}
