import React from "react";
import { useReactTable, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, ColumnDef, flexRender, Row } from "@tanstack/react-table";
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Flex,
	IconButton,
	Text,
	Tooltip,
	Select,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	TableProps,
	StyleProps
} from "@chakra-ui/react";
import {
	ArrowRightIcon,
	ArrowLeftIcon,
	ChevronRightIcon,
	ChevronLeftIcon
} from "@chakra-ui/icons";

interface PaginatedTableProps extends TableProps {
	columns: ColumnDef<any>[]; //TODO
	data: any;
	onRowClick?(row: Row<any>): void;
	onRowHover?: Object;
}

export const PaginatedTable = ({ columns, data, onRowClick, onRowHover, ...rest }: PaginatedTableProps) => {
	const {
		getHeaderGroups,
		getRowModel,
		getCanPreviousPage,
		getCanNextPage,
		getPageCount,
		setPageIndex,
		previousPage,
		nextPage,
		setPageSize,
		getState,
	} = useReactTable(
		{
			columns,
			data,
			getCoreRowModel: getCoreRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			getPaginationRowModel: getPaginationRowModel(),
		}
	);

	const hover = onRowHover ? { _hover:  onRowHover  } : {};
	return (
		<>
			<Table {...rest} >
				<Thead>
					{getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id} >
							{headerGroup.headers.map((header) => (
								<Th                   {...{
									key: header.id,
									colSpan: header.colSpan,
									style: {
										width: header.getSize(),
									},
								}} >
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody >
					{getRowModel().rows.map((row) => {
						return (
							<Tr {...hover} onClick={() => onRowClick && onRowClick(row)} key={row.id} >
								{row.getVisibleCells().map((cell) => {
									return (
										<Td
											{...{
												key: cell.id,
												style: {
													width: cell.column.getSize(),
												},
											}} >
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>

			<Flex justifyContent="space-between" m={4} alignItems="center">
				<Flex>
					<Tooltip label="Första">
						<IconButton
							onClick={() => setPageIndex(0)}
							isDisabled={!getCanPreviousPage()}
							aria-label="Första"
							icon={<ArrowLeftIcon h={3} w={3} />}
							mr={4}
						/>
					</Tooltip>
					<Tooltip label="Föregående">
						<IconButton
							onClick={previousPage}
							aria-label="Föregående"
							isDisabled={!getCanPreviousPage()}
							icon={<ChevronLeftIcon h={6} w={6} />}
						/>
					</Tooltip>
				</Flex>

				<Flex alignItems="center">
					<Text flexShrink="0" mr={8}>

						<Text fontWeight="bold" as="span">
							{getState().pagination.pageIndex + 1}
						</Text>{" "}
						/{" "}
						<Text fontWeight="bold" as="span">
							{getPageCount()}
						</Text>
					</Text>
					<Text flexShrink="0">Sida:</Text>{" "}
					<NumberInput
						ml={2}
						mr={8}
						w={28}
						min={1}
						max={getPageCount()}
						onChange={(value) => {
							const page = value ? Number(value) - 1 : 0;
							setPageIndex(page);
						}}
						defaultValue={getState().pagination.pageIndex + 1}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<Select
						w={32}
						value={getState().pagination.pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
					>
						{[10, 25, 50, 100, 500, 1000].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</Select>
				</Flex>

				<Flex>
					<Tooltip label="Nästa">
						<IconButton
							onClick={nextPage}
							aria-label="Nästa"
							isDisabled={!getCanNextPage()}
							icon={<ChevronRightIcon h={6} w={6} />}
						/>
					</Tooltip>
					<Tooltip label="Sista">
						<IconButton
							onClick={() => setPageIndex(getPageCount() - 1)}
							aria-label="Sista"
							isDisabled={!getCanNextPage()}
							icon={<ArrowRightIcon h={3} w={3} />}
							ml={4}
						/>
					</Tooltip>
				</Flex>
			</Flex>
		</>
	);
}
