import {
	Button,
	FormControl,
	Flex,
	Heading,
	Input,
	Stack,
	FormLabel,
	Text,
	useColorModeValue,
	useToast,
	FormErrorMessage,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { passwordSchema } from '../validation';

// type ForgotPasswordFormInputs = {
// 	email: string;
// };

//TODO enable this and make it so it posts to backend
export const ForgotPasswordForm = (): JSX.Element => {
	return (

		<Flex
			align={'center'}
			justify={'center'}
			bg={useColorModeValue('gray.50', 'gray.800')}>
			<Stack
				spacing={4}
				w={'full'}
				maxW={'md'}
				bg={useColorModeValue('white', 'gray.700')}
				rounded={'xl'}
				boxShadow={'lg'}
				p={6}
				my={12}>
				<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
					Glömt ditt lösenord?
				</Heading>
				<Text
					fontSize={{ base: 'sm', sm: 'md' }}
					color={useColorModeValue('gray.800', 'gray.400')}>
					Du kommer få en återställningslänk via epost.
				</Text>
				<FormControl id="email">
					<Input
						placeholder="namn@domän.se"
						_placeholder={{ color: 'gray.500' }}
						type="email"
					/>
				</FormControl>
				<Stack spacing={6}>
					<Button
						bg={'teal.400'}
						color={'white'}
						_hover={{
							bg: 'teal.500',
						}}>
						Skicka
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}

export const ForgotPasswordRequestForm = (): JSX.Element => {
	const toast = useToast();
	const navigate = useNavigate();
	const { token } = useParams() as { token: string };

	const colorMode1 = useColorModeValue('gray.50', 'gray.800')
	const colorMode2 = useColorModeValue('white', 'gray.700')

	const handleSubmit = async (values: { password: string }) => {
		const response = await fetch(`/api/reset-password-v1/${token}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ password: values.password })
		});
		if (!response.ok) {
			toast({
				title: `Byte av lösenord misslyckades. Det kan beror på felaktig eller för gammal länk`,
				status: "error",
				isClosable: true,
			})
			throw new Error("login failed");
		}

		toast({
			title: `Lösenordet sparat, du kan nu logga in`,
			status: "success",
			isClosable: true,
		})
		navigate("/login")
	}
	return (
		<Formik
			initialValues={{ password: '', confirmPassword: '' }}
			validationSchema={passwordSchema}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, errors, touched }) => (
				<form autoComplete="off" onSubmit={handleSubmit}>
					<Flex
						align={'center'}
						justify={'center'}
						bg={colorMode1}>
						<Stack
							spacing={4}
							w={'full'}
							maxW={'md'}
							bg={colorMode2}
							rounded={'xl'}
							boxShadow={'lg'}
							p={6}
							my={12}>
							<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
								Byt eller skapa lösenord
							</Heading>
							<FormControl isInvalid={!!errors.password && touched.password}>
								<FormLabel >Nytt lösenord</FormLabel>
								<Field
									autoFocus={true}
									data-lpignore='true'
									as={Input}
									name="password"
									type='password'
								/>
								<FormErrorMessage>{errors.password}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!errors.confirmPassword && touched.confirmPassword}>
								<FormLabel >Bekräfta lösenord</FormLabel>
								<Field
									data-lpignore='true'
									as={Input}
									name="confirmPassword"
									type='password'
								/>
								<FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
							</FormControl>
							<Stack spacing={6}>
								<Button
									colorScheme='teal'
									color={'white'}
									type="submit">
									Spara lösenordet
								</Button>
							</Stack>
						</Stack>
					</Flex>
				</form>
			)}
		</Formik>
	);
}
