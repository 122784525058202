import {
	Box,
	SimpleGrid,
	Heading,
	Container,
	Text,
	Stack,
	HStack,
	VStack,
	Icon,
	Tabs,
	TabList,
	Tab,
	TabPanel,
	TabPanels,
	Button,
	List,
	ListItem,
	ListIcon,
	GridItem,
	Grid,
	Divider,
	useColorModeValue,
	ButtonProps,
	Tooltip,
	Center,
	Link,
	UnorderedList,
} from '@chakra-ui/react';

import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import { ChildrenProps } from '../../types';
import { useNavigate, useParams } from 'react-router-dom';
import { MouseEventHandler, useState } from 'react';

const features = [
	{
		id: 1,
		title: 'Värm när priset är lågt',
		text: 'Kör värmepump eller elpanna när priset är lågt.',
	},
	{
		id: 2,
		title: 'Få statistik från din värmepump',
		text: 'Värden från din värmepump loggas var 30\'e sekund',
	},
	{
		id: 3,
		title: 'Visualisera statistiken',
		text: 'statistiken kan du enkelt visualiera här på hemsidan när du loggat in.',
	},
	{
		id: 4,
		title: 'Värm varmvatten smart',
		text: 'Förbered morgonduschen genom att värma varmvattnet billigaste timmen på natten.',
	},
	{
		id: 5,
		title: 'Larm',
		text: 'Få larm via E-post från värmepumpen eller andra inkopplade källor.',
	},
]
	;




export const AboutPageGeneral = () => {
	return (
		<Box>
			<Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'left'} pt={{ base: 20, md: 24 }}>
				<Heading textAlign={'center'} fontSize={'3xl'}>Smart styrning av varmvatten och värme</Heading>
				<Text color={'gray.600'} fontSize={'xl'}>
					Styr din varmvattenberedning och uppvärmning smart och betala mindre per månad.
					Elen är oftast som dyras på dagarna och med vår tjänst styr du din utrustning
					baserat på elpriset.
				</Text>
			</Stack>

			<Container maxW={'6xl'} mt={10} pb={{ base: 20, md: 24 }}>
				<SimpleGrid columns={{ base: 1, md: 2, lg: 5 }} spacing={10}>
					{features.map((feature) => (
						<HStack key={feature.id} align={'top'}>
							<Box color={'green.400'} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={'start'}>
								<Text fontWeight={600}>{feature.title}</Text>
								<Text color={'gray.600'}>{feature.text}</Text>
							</VStack>
						</HStack>
					))}
				</SimpleGrid>
			</Container>
		</Box>
	);

}

export const AboutPageContact = () => {
	const [showingEmail, setShowingEmail] = useState(false);
	const email = showingEmail ? (
		<span>info@nergy.se</span>
	) : (
		<Button
			colorScheme='teal'
			onClick={() => setShowingEmail(true)}
		>
			Visa epost
		</Button>
	);
	return (
		<Box>
			<Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'left'} pt={{ base: 10, md: 14 }} pb='10'>
				<Heading textAlign={'left'} fontSize={'3xl'}>Kontakta oss vid frågor eller om ni vill beställa.</Heading>
				<Text color={'gray.600'} fontSize={'xl'}>
					{email}
				</Text>
			</Stack>

		</Box>
	);
}

export const AboutPageHow = () => {

	return (
		<Box>
			<Stack spacing={4} as={Container} maxW={'4xl'} textAlign={'left'} pt={{ base: 10, md: 14 }} pb='10'>
				<Heading fontSize={'4xl'}>Hur funkar det?</Heading>
			</Stack>
			<Stack spacing={4} as={Container} maxW={'4xl'} textAlign={'left'} pb='10'>
				<Text>
					Grundprincipen är att med hjälp av timpriset på elen styra värmepumpar, elpatroner etc så dessa jobbar som mest när priset på elen är lågt.
					Man köper en box av oss eller så kör man mjukvaran på egen hårdvara och prenumererar då bara på tjänsten.
				</Text>
				<Text>
					Vi har även mer specialanpassade lösningar om man i en större fastighet har både fjärrvärme och en värmepump.
					Till exempel vill man kanske begränsa uttaget av fjärrvärme för att hålla sig under en viss effekttariff eller
					kör värmepumpen extra forcerat när det elpriset är som billigast. Detta förutsätter att man kopplar upp sig mot fjärrvärmemätaren med M-bus.
				</Text>
				<Text>
					I dagsläget stödjer vi följande värmepumpar:
				</Text>
				<List pl={'10px'} spacing={3}>
					<ListItem>
						<ListIcon as={CheckCircleIcon} color='green.500' />
						Thermia (med genesis styrningen) via modbus TCP
					</ListItem>
					<ListItem>
						<ListIcon as={CheckCircleIcon} color='green.500' />
						Externt relä (kontaktor), för att tex styra en varmvattenberedare eller elpatron.
					</ListItem>
				</List>
			</Stack>

		</Box>
	);
}

export const AboutPagePrices = () => {
	const { id } = useParams() as { id: string };
	const navigate = useNavigate();
	// startpaket 5000kr privat
	// startpaket 8000kr företag
	// 99kr måndagen för värmepump privat
	// 200kr månaden för fjärrvärme + värmepump företag
	let defaultIndex = 0;
	if (id === 'company') {
		defaultIndex = 1;
	}
	return (
		<Box>
			<Stack spacing={4} as={Container} maxW={'6xl'} pt={{ base: 10, md: 14 }} pb='10'>
				<Heading fontSize={'3xl'}>Priser</Heading>
			</Stack>
			<Container maxW={'6xl'} pb='10'>
				<Tabs defaultIndex={defaultIndex} onChange={(index) => navigate(index == 0 ? '/about/prices' : '/about/prices/company')}>
					<TabList>
						<Tab>Privat</Tab>
						<Tab>Företag</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<PrislistaPrivat />
						</TabPanel>
						<TabPanel>
							<PrislistaFöretag />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Container>
		</Box>
	);
}

interface PackageTierProps {
	title: string
	options: Array<{ id: number; desc: string; hoverText?: string; }>
	typePlan: string
	buttonText?: string
	buttonOnClick?: MouseEventHandler<HTMLButtonElement>
}
const PackageTier = ({ title, options, typePlan, buttonText = '', buttonOnClick }: PackageTierProps) => {
	const colorTextLight = buttonText != '' ? 'white' : 'teal.600'
	const bgColorLight = buttonText != '' ? 'teal.400' : 'gray.300'

	const colorTextDark = buttonText != '' ? 'white' : 'teal.500'
	const bgColorDark = buttonText != '' ? 'teal.400' : 'gray.300'
	const buttonBg = useColorModeValue(bgColorLight, bgColorDark);
	const buttonColor = useColorModeValue(colorTextLight, colorTextDark);

	return (
		<Grid templateColumns='repeat(8, 1fr)'
			py={3}
			alignItems={{ md: 'center' }}>
			<GridItem colSpan={2}>
				<Heading size={'md'}>{title}</Heading>
			</GridItem>
			<GridItem colSpan={2}>
				<List spacing={3} textAlign="start">
					{options.map((desc, id) => (
						<Tooltip isDisabled={(desc.hoverText ?? '') == ''} label={desc.hoverText ?? ''}>
							<ListItem key={desc.id}>
								<ListIcon as={CheckCircleIcon} color="green.500" />
								{desc.desc}
							</ListItem>
						</Tooltip>
					))}
				</List>
			</GridItem>
			<GridItem colSpan={2}>
				<Heading size={'xl'}>{typePlan}</Heading>
			</GridItem>
			<GridItem colSpan={1}>
				{buttonText != '' &&
					<Button
						size="md"
						onClick={buttonOnClick}
						_hover={{
							bg: 'teal.500',
						}}
						color={buttonColor}
						bgColor={buttonBg}>
						{buttonText}
					</Button>
				}
			</GridItem>
		</Grid>
	)
}
const PrislistaPrivat = () => {

	const navigate = useNavigate();

	return (
		<Box py={6} px={5} width="full">
			<Stack spacing={4} width={'100%'} direction={'column'}>
				<PackageTier title={'NergyKontroller'} typePlan="2999 kr" options={[
					{ id: 1, desc: 'Gratis frakt inom sverige' },
				]} buttonText='Beställ' buttonOnClick={(e) => navigate("/register")} />
				<Divider />
				<PackageTier
					title={'Abonnemang'}
					typePlan="99 kr/mån"
					options={[
						{ id: 1, desc: 'Larm via epost' },
						{ id: 2, desc: 'Samlar data var 30 sekund' },
						{ id: 3, desc: 'Data sparas för alltid*', hoverText: 'Så länge abonnemanget är aktivt' },
						{ id: 4, desc: 'Elpris styrning per timme' },
						{ id: 5, desc: 'Effektariff styrning*', hoverText: 'Förutsätter att Elmätare kopplas upp mot NergyKontroller' },
						{ id: 6, desc: 'Uppdateringar av NergyKontroller' },
					]}
				/>
				<Divider />
				<Text align={'right'}>Alla priser ovan visas inkl moms</Text>
			</Stack>
		</Box>
	)
}

const PrislistaFöretag = () => {
	const navigate = useNavigate();
	return (
		<Box py={6} px={5} width="full">
			<Stack spacing={4} width={'100%'} direction={'column'}>
				<Stack
					pt={5}
					pb={5}
					alignItems={'center'}
					justifyContent={{
						base: 'flex-start',
						md: 'space-around',
					}}
					direction={{
						base: 'column',
						md: 'row',
					}}>
					<Stack
						width={{
							base: '100%',
							md: '40%',
						}}
						textAlign={'left'}>
						<Heading size={'lg'}>
							Spara pengar i dina fastigheter <Text as={'span'} color="teal.400">idag</Text>
						</Heading>
					</Stack>
					<Stack
						width={{
							base: '100%',
							md: '60%',
						}}>
						<Text textAlign={'left'}>
							Beställ en kontroller och koppla sedan in detta till er värmepump.
							Vi kan även agera överordnat styrsystem och styra hybrid lösningar med både fjärrvärme och värmepumpar.
						</Text>
					</Stack>
				</Stack>
				<Divider />
				<Heading pb={5} pt={5} textAlign={'left'} fontSize={'3xl'}>Hårdvara</Heading>
				<PackageTier title={'NergyKontroller'} typePlan="5000 kr" options={[]} buttonText='Beställ' buttonOnClick={(e) => navigate("/register")} />
				<PackageTier title={'M-bus mätmodul'} typePlan="1500 kr" options={[
					{ id: 1, desc: 'Upp till 6 mätare' },
				]} buttonText='Beställ' buttonOnClick={(e) => navigate("/register")} />
				<Divider />
				<Stack
					pb={5}
					pt={5}
					alignItems={'center'}
					direction={{
						base: 'column',
						md: 'row',
					}}>
					<Stack
						pl={0}
						width={{
							base: '100%',
							md: '40%',
						}}
						textAlign={'left'}>
						<Heading textAlign={'left'} fontSize={'3xl'}>Tjänster</Heading>
					</Stack>
					<Stack
						width={{
							base: '100%',
							md: '60%',
						}}>
						<Text textAlign={'left'}>
							Alla tjänster faktureras per kontroller där de används/aktiverats.
						</Text>
					</Stack>
				</Stack>
				<PackageTier
					title={'Värmepump'}
					typePlan="99 kr/mån"
					options={[
						{ id: 1, desc: 'Data sparas för alltid*', hoverText: 'Så länge abonnemanget är aktivt' },
						{ id: 2, desc: 'Larm via epost' },
						{ id: 3, desc: 'Samlar data var 30 sekund' },
						{ id: 4, desc: 'Timpris styrning' },
						{ id: 6, desc: 'Uppdateringar av NergyKontroller' },
					]}
				/>
				<PackageTier
					title={'Effektkontroll EL'}
					typePlan="49 kr/mån"
					options={[
						{ id: 1, desc: 'Effektariff styrning*', hoverText: 'Förutsätter att Elmätare kopplas upp mot NergyKontroller med m-bus' },
					]}
				/>
				<PackageTier
					title={'Fjärrvärme'}
					typePlan="99 kr/mån"
					options={[
						{ id: 1, desc: 'Styr tillsamans med elpriset' },
					]}
				/>
				<PackageTier
					title={'Effektkontroll fjärrvärme'}
					typePlan="49 kr/mån"
					options={[
						{ id: 1, desc: 'Effektariff styrning*', hoverText: 'Förutsätter att fjärrvärmemätare kopplas upp mot NergyKontroller med m-bus' },
					]}
				/>
				<Divider />
				<Text align={'right'}>Alla priser ovan visas exkl moms</Text>
			</Stack>
		</Box>
	)
}
export const PrivacyPolicy = () => {

	return (
		<Box>
			<Stack spacing={4} as={Container} maxW={'4xl'} textAlign={'left'} pt={{ base: 10, md: 14 }} pb='10'>
				<Heading fontSize={'4xl'}>Personuppgiftspolicy</Heading>
			</Stack>
			<Stack spacing={4} as={Container} maxW={'4xl'} textAlign={'left'} pb='10'>
				<Text>
					För Nergy är viktigt att skydda sina anställdas och kunders personuppgifter.
					Vi tillämpar och följer Europaparlamentets och Rådets förordning (EU) 2016/679 (GDPR).
					I denna policy förklarar vi hur vi behandlar dina personuppgifter.
					Vi beskriver också dina rättigheter och hur du kan göra dem gällande.
					Som kund är du välkommen att kontakta oss om du har frågor om hur vi behandlar dina personuppgifter.
					Våra kontaktuppgifter hittar du <Link href="/about/contact">här</Link>.
				</Text>
				<Heading as='h4' size='md'>Vad lagrar vi?</Heading>
				<Text>
					Vi lagrar och använder följande data
				</Text>
				<UnorderedList pl={'10px'}>
					<ListItem>Namn</ListItem>
					<ListItem>Adress</ListItem>
					<ListItem>Epost</ListItem>
					<ListItem>Din IP adress</ListItem>
				</UnorderedList>
				<Heading as='h4' size='md'>Hur använder vi din information?</Heading>
				<Text>
					Vi använder din information på följande sätt.
				</Text>
				<UnorderedList pl={'10px'}>
					<ListItem>Skicka faktura via post och och epost</ListItem>
					<ListItem>Skicka notiser och larm från tjänsten till din epost</ListItem>
					<ListItem>Din IP adress syns aldrig på websidan men kan sparas i vissa loggar.</ListItem>
				</UnorderedList>
				<Heading as='h4' size='md'>Din information är trygg hos oss</Heading>
				<Text>
					Vi gör vårt bästa för att se till att dina personliga uppgifter skyddas och förvaras på ett säkert sätt.
					All datakommunikation till och från användarens datorer krypteras med TLS, den mest använda standarden för krypterad kommunikation på internet.
					Vi utför daglig backup av systemet.
				</Text>
				<Heading as='h4' size='md'>Cookie-policy</Heading>
				<Text>
					En cookie eller kaka är en liten textbaserad datafil som en webbserver kan be att få spara i besökarens dator.
					Genom att kakorna i allmänhet skickas tillbaka med varje förfrågan till webbplatsen ifråga är
					det möjligt för servern att hålla reda på besökarnas inställningar.
				</Text>
				<Text>Vi använder endast cookies för att spara att användaren är inloggad vid inloggning så att man kan hållas inloggad i tjänsten.</Text>
				<Heading as='h4' size='md'>Rättigheter</Heading>
				<Text>
					Du har rätt att ta del av de personliga data som vi förvarar om dig och erhålla en kopia av informationen.
					För att begära en kopia av den personliga information vi lagrar om dig kan du kontakta oss.
					Om du anser att informationen vi lagrat är ofullständig eller felaktig är du också välkommen att kontakta oss.
				</Text>
				<Text>
					Du har också rätt att begära att din personliga information blir borttagen. Vänligen kontakta oss om du önskar att vi raderar din personliga information.
				</Text>
				<Heading as='h4' size='md'>Acceptera detta användaravtal</Heading>
				<Text>
					Fortsatt användning av vår tjänst medför att du accepterar denna policy.
					Vid registrering på webbplatsen kommer vi att begära att du bekräftar att du tagit del av Personuppgiftspolicyn samt godkänner den.
				</Text>
				<Heading as='h4' size='md'>Förändringar i detta användaravtal</Heading>
				<Text>
					Vi kan komma att införa förändringar i detta avtal när som helst. Du kan därför få frågan att granska och åter acceptera denna policy om det förändras i framtiden.
				</Text>
				<Text>Detta dokument senast uppdaterat: 2024-01-28</Text>
			</Stack>

		</Box>
	);
}
