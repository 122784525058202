import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	HStack,
	InputRightElement,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
	Link,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

export const RegisterPage = () => {
	const toast = useToast()
	const [showPassword, setShowPassword] = useState(false);

	const[firstName, setFirstName ] = useState('');
	const[lastName, setLastName ] = useState('');
	const[ email, setEmail ] = useState('');
	const[ password, setPassword ] = useState('');
	const[confirmPassword, setConfirmPassword ] = useState('');

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (password !== confirmPassword){
			toast({
				title: `Lösenorden är olika.`,
				status: "error",
				isClosable: true,
			})
			return;
		}

		//TODO implement backend endpoint
		const response = await fetch('/api/register-v1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ 
				firstName: firstName,
				lastName: lastName,
				email: email,
				password: password,
			})
		});
		if (!response.ok) {
			toast({
				title: `Inloggning misslyckades.`,
				status: "error",
				isClosable: true,
			})
			throw new Error("login failed");
		}
		// navigate('/inside/home');
	}

return (
		<Flex
			align={'center'}
			justify={'center'}
			bg={useColorModeValue('gray.50', 'gray.800')}>
			<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
				<Stack align={'center'}>
					<Heading fontSize={'4xl'} textAlign={'center'}>
						Skapa konto och beställ
					</Heading>
					{/* <Text fontSize={'lg'} color={'gray.600'}> */}
					{/* 	to enjoy all of our cool features ✌️ */}
					{/* </Text> */}
				</Stack>
				<Box
					rounded={'lg'}
					bg={useColorModeValue('white', 'gray.700')}
					boxShadow={'lg'}
					p={8}>
					Registreringen är inte riktigt öppen ännu.<br/> Kontakta information@nergy.se ifall du är intresserad av att bli kund!
					
				</Box>
			</Stack>
		</Flex>
	);

	return (
		<Flex
			align={'center'}
			justify={'center'}
			bg={useColorModeValue('gray.50', 'gray.800')}>
			<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
				<Stack align={'center'}>
					<Heading fontSize={'4xl'} textAlign={'center'}>
						Skapa konto
					</Heading>
					{/* <Text fontSize={'lg'} color={'gray.600'}> */}
					{/* 	to enjoy all of our cool features ✌️ */}
					{/* </Text> */}
				</Stack>
				<Box
					rounded={'lg'}
					bg={useColorModeValue('white', 'gray.700')}
					boxShadow={'lg'}
					p={8}>
					<form onSubmit={handleSubmit}>
						<Stack spacing={4}>
							<HStack>
								<Box>
									<FormControl id="firstName" isRequired>
										<FormLabel>Förnamn</FormLabel>
										<Input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" />
									</FormControl>
								</Box>
								<Box>
									<FormControl id="lastName">
										<FormLabel>Efternamn</FormLabel>
										<Input value={lastName} onChange={e => setLastName(e.target.value)} type="text" />
									</FormControl>
								</Box>
							</HStack>
							<FormControl id="email" isRequired>
								<FormLabel>E-postadress</FormLabel>
								<Input value={email} onChange={e => setEmail(e.target.value)} type="email" />
							</FormControl>
							<FormControl id="password" isRequired>
								<FormLabel>Lösenord</FormLabel>
								<InputGroup>
									<Input value={password} onChange={e => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} />
									<InputRightElement h={'full'}>
										<Button
											variant={'ghost'}
											onClick={() =>
												setShowPassword((showPassword) => !showPassword)
											}>
											{showPassword ? <ViewIcon /> : <ViewOffIcon />}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>
							<FormControl id="confirm-pw" isRequired>
								<FormLabel>Bekräfta lösenord</FormLabel>
								<InputGroup>
									<Input value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} type={showPassword ? 'text' : 'password'} />
								</InputGroup>
							</FormControl>
							<Stack spacing={10} pt={2}>
								<Button
									type='submit'
									loadingText="Submitting"
									size="lg"
									bg={'blue.400'}
									color={'white'}
									_hover={{
										bg: 'blue.500',
									}}>
									Skapa konto
								</Button>
							</Stack>
							<Stack pt={6}>
								<Text align={'center'}>
									Har du redan ett konto? <Link as={RouterLink} to='/login' color={'blue.400'}>Logga in</Link>
								</Text>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
		</Flex>
	);
}
