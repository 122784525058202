import { object, string, ref } from "yup";

const getCharacterValidationError = (str: string) => {
  return `Lösenordet måste ha minst 1 ${str}`;
};
export const passwordSchema = object({
  password: string()
    .required("Ange ett lösenord")
    // check minimum characters
    .min(8, "Minst 8 tecken")
    // different error messages for different requirements
    .matches(/[0-9]/, getCharacterValidationError("siffra"))
    .matches(/[a-zöäå]/, getCharacterValidationError("liten bokstav"))
    .matches(/[A-ZÖÄÅ]/, getCharacterValidationError("stor bokstav")),
  confirmPassword: string()
    .required("Ange lösenordet igen")
    // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of passwrod.
    .oneOf([ref("password")], "Passwords does not match"),
});
