import React, { useState } from 'react';
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Checkbox,
	Tooltip,
	useToast,
	useColorModeValue,
    Link,
} from '@chakra-ui/react';

import { useAppDispatch } from '../app/hooks';
import { login } from '../features/user/userSlice'
import { Link as RouterLink, useNavigate } from 'react-router-dom'




export const LoginPage = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch();
	const toast = useToast()
	const [username, setUserName] = useState<string | null>();
	const [password, setPassword] = useState<string | null>();
	const [allowCookies, setAllowCookies] = useState<boolean>(true);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const response = await fetch('/api/login-v1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ username: username, password: password })
		});
		if (!response.ok) {
			toast({
				title: `Inloggning misslyckades.`,
				status: "error",
				isClosable: true,
			})
			throw new Error("login failed");
		}
		let json = await response.json();
		dispatch(login(json));
		navigate('/inside/home');
	}
	return (
		<Flex
			align={'center'}
			justify={'center'}
			bg={useColorModeValue('gray.50', 'gray.800')}>
			<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
				<Box
					rounded={'lg'}
					bg={useColorModeValue('white', 'gray.700')}
					boxShadow={'lg'}
					p={8}>
					<Stack spacing={4}>
						<form onSubmit={handleSubmit}>
							<FormControl id="email">
								<FormLabel>E-post</FormLabel>
								<Input type="email" autoFocus onChange={e => setUserName(e.target.value)} />
							</FormControl>
							<FormControl id="password">
								<FormLabel>Lösenord</FormLabel>
								<Input type="password" onChange={e => setPassword(e.target.value)} />
							</FormControl>
							<FormControl id="allowCookies">
							<Checkbox pt='10px' defaultChecked={true} checked={allowCookies} type="allowCookies" onChange={e => setAllowCookies(e.target.checked)} >
								<Tooltip label='Dessa är nödvändiga för att webbläsaren skall kunna hålla koll på att du är inloggad.'>
									Tillåt nödvändiga kakor (cookies).
								</Tooltip>
							</Checkbox>
							</FormControl>
							<Stack mt='10px' spacing={0}>
								<Button
									type='submit'
									disabled={!allowCookies}
									bg={'teal.400'}
									color={'white'}
									_hover={{
										bg: 'teal.500',
									}}>
									Logga in
								</Button>
								<Stack
									direction={{ base: 'column', sm: 'row' }}
									align={'start'}
									justify={'space-between'}>
									 {/* <Link as={RouterLink} to='/forgotten' color={'blue.400'}>Glömt lösenord?</Link>*/} 
								</Stack>
							</Stack>
						</form>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}
