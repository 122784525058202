import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types'



const initialState = (): User => {
	const user = localStorage.getItem('user');
	if (!user) {
		return {
			email: '',
			id: '',
			name: '',
			rights: [],
			username: '',
			personalIdentityNumber: '',
		}
	}
	return JSON.parse(user) as User;
};
// TODO save whole user to localstorage???

export const userSlice = createSlice({
	name: 'user',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		login: (state, action: PayloadAction<any>) => {
			const rights = action.payload.roles?.reduce((acc: any, cur: any) => {
				return acc.concat(cur.rights);
			}, []);
			state.email = action.payload.email;
			state.id = action.payload.id;
			state.name = action.payload.name;
			state.rights = rights;
			state.username = action.payload.username;
			localStorage.setItem('user', JSON.stringify(state));
		},
		logout: () => {
			localStorage.removeItem('user');
			return initialState()
		}
	}
	// extraReducers: {},

});


export const checkRight = (user: User, right: string): boolean => {
	if (!user) {
		return false;
	}
	if (!user.rights) {
		return false;
	}
	return user.rights?.includes(right);
};

export const { login, logout } = userSlice.actions;


export default userSlice.reducer;
