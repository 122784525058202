import React from 'react';
import { Counter } from './features/counter/Counter';
import {
	ChakraProvider,
	Text,
	theme,
	Alert,
	AlertTitle,
	AlertIcon,
	Button,
	AlertDescription,
} from "@chakra-ui/react"
import { NavMenu, Footer } from './features/publicMenu/menu';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link as RouterLink,
} from 'react-router-dom';
import { StartPage } from './pages/public/start';
import { AboutPageContact, AboutPageGeneral, AboutPageHow, AboutPagePrices, PrivacyPolicy } from './pages/public/about';
import { InsideHomePage } from './pages/inside/home';
import { ControllerAlarmPage,  ControllerStatisticsPage, ControllerStatusPage } from './pages/inside/controller';
import { LoginPage } from './pages/login';
import { RegisterPage } from './pages/public/register';
import { ForgotPasswordForm, ForgotPasswordRequestForm } from './pages/password';

import { useAppSelector, useAppDispatch } from './app/hooks';
import { NavLink, Outlet } from 'react-router-dom'
import { AdminControllerAddNewPage, AdminControllerPage, AdminControllersPage, AdminStatusPage, AdminTenantPage, AdminTenantsPage, AdminUserPage, AdminUsersPage } from './pages/admin/admin';
import { SettingsPage } from './pages/inside/settings';
import { ControllerSettingsPage } from './pages/inside/controllerSettings';
import { CalculateSavings } from './features/calculateSavings/calculateSavings';

export const App = () => {

	// TODO conditional if we are logged in or not when display public site react component
	return (
		<ChakraProvider theme={theme}>
			<Router>
				<NavMenu />
				<Routes>
					<Route path="/calculate" element={<CalculateSavings />} />
					<Route path="/about" element={<AboutPageGeneral />} />
					<Route path="/about/how" element={<AboutPageHow />} />
					<Route path="/about/prices" element={<AboutPagePrices />} />
					<Route path="/about/prices/:id" element={<AboutPagePrices />} />
					<Route path="/about/contact" element={<AboutPageContact />} />
					<Route path="/about/privacypolicy" element={<PrivacyPolicy />} />
					<Route path="/" element={<StartPage />} />
					<Route path="/test" element={<Counter />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/register" element={<RegisterPage />} />
					<Route path="/forgotten" element={<ForgotPasswordForm />} />
					<Route path="/new-password/:token" element={<ForgotPasswordRequestForm />}/>
					<Route element={<ProtectedRoute />}>
						<Route path='/inside/home' element={<InsideHomePage />} />
						<Route path='/inside/controller/:id/status' element={<ControllerStatusPage />} />
						<Route path='/inside/controller/:id/statistics' element={<ControllerStatisticsPage />} />
						<Route path='/inside/controller/:id/alarms' element={<ControllerAlarmPage />} />
						<Route path='/inside/controller/:id/settings' element={<ControllerSettingsPage />} />
						<Route path='/inside/settings' element={<SettingsPage />} />
					</Route>
					<Route element={<ProtectedRoute rights={["tenants.write"]} />}>
						<Route path='/inside/admin/controllers' element={<AdminControllersPage/>} />
						<Route path='/inside/admin/controllers/new' element={<AdminControllerAddNewPage/>} />
						<Route path='/inside/admin/controllers/:id' element={<AdminControllerPage/>} />
						<Route path='/inside/admin/status' element={<AdminStatusPage/>} />
						<Route path='/inside/admin/tenants' element={<AdminTenantsPage/>} />
						<Route path='/inside/admin/tenants/:id' element={<AdminTenantPage/>} />
						<Route path='/inside/admin/users' element={<AdminUsersPage/>} />
						<Route path='/inside/admin/users/:id' element={<AdminUserPage/>} />
					</Route>
				</Routes>
				<Footer />
			</Router>
		</ChakraProvider>
	);
};
export interface ProtectedRouteProps {
	rights?: string[];
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
	const user = useAppSelector((state) => state.user)

	const unauthed = (
		<Alert status='error'
			variant='subtle'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			textAlign='center'
			height='200px'>
			<AlertIcon boxSize='40px' mr={0}  />
			<AlertTitle mt={4} mb={1} fontSize='lg'>
				Du har blivit utloggad
			</AlertTitle>
			<AlertDescription maxWidth='sm'>
				{/* <NavLink to='/login'>Logga in igen</NavLink> */}
		<Button
			as={RouterLink}
			// fontSize={'sm'}
			// fontWeight={400}
			variant={'link'}
			to='/login'>
			Logga in
		</Button>
			</AlertDescription>
		</Alert>
	)

	// show unauthorized screen if no user is found in redux store
	if (!user.id) {
		return unauthed;
	}
	if (props.rights !== undefined) {
		let allowed = false;
		props.rights.forEach(function(right: string) {
			if (user.rights?.includes(right)) {
				allowed = true;
			}
		});
		if (!allowed) {

			return unauthed;
		}
	}

	// returns child route elements
	return <Outlet />
}
