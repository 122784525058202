import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spinner, Table, TableContainer, Tbody, Td, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react"
import { Row } from "@tanstack/react-table";
import { Field, Formik } from "formik";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { MeterSetting, User } from "../../types";
import { useAddControllerMeterSettingMutation, useDeleteControllerMeterSettingMutation, useGetControllerMeterSettingsQuery, useGetUsersQuery, useUpdateControllerMeterSettingMutation } from "../api/api";
import { PaginatedTable } from "../table/table"


export const MeterSettingsTable = () => {

	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllerMeterSettingsQuery(id, { refetchOnMountOrArgChange: true });
	const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure();
	const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
	const [editingMeter, setEditingMeter] = useState<MeterSetting | undefined>();
	const toast = useToast();
	//TODO lägg till column med när vi senast hämtade data?
	const { current: cols } = useRef([
		{
			header: "Typ",
			accessorKey: "interfaceType",
			// cell: (row: any) => console.log(row.getValue()),
			size: 50
		},
		{
			header: "Modell",
			accessorKey: "model"
		},
		{
			header: "ID",
			accessorKey: "primaryId"
		},
	]);

	if (isLoading) {
		return <Spinner size='xl' />;
	}

	const onRowClick = (row: Row<any>) => {
		setEditingMeter(row.original)
		onOpenEdit()
	}
	return (
		<>

			<Grid templateColumns='repeat(5, 1fr)' >
				<GridItem colSpan={3}>
					<Heading pb='20px' as='h1' size='md' >
						Mätare
					</Heading>
				</GridItem>
				<GridItem colSpan={1} />
				<GridItem colSpan={1} textAlign='right'>
					<Button
						onClick={() => {
							setEditingMeter({ controllerId: id, interfaceType: "", model: "", primaryId: "", position:"" })
							onOpenEdit()
						}}
						bg={'teal.400'}
						color={'white'}
						rounded={'lg'}
						_hover={{
							bg: 'teal.500',
						}}
						_focus={{
							bg: 'teal.500',
						}}>
						Lägg till
					</Button>
				</GridItem>
			</Grid>
			<TableContainer>
				<Modal isOpen={isOpenEdit} onClose={onCloseEdit}>
					<ModalOverlay />
					<ModalContent minWidth="fit-content" height="fit-content">
						<ModalHeader>{editingMeter?.id ? "Ändra" : "Skapa"}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<ModalEditMeter onClose={onCloseEdit} meter={editingMeter} />
						</ModalBody>

						<ModalFooter>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<PaginatedTable onRowHover={{
					bg: 'teal.400',
					color: 'white',
					cursor: 'pointer',
				}} onRowClick={onRowClick} __css={{ tableLayout: 'fixed', width: 'full' }} size='sm' columns={cols} data={data} />

			</TableContainer>
		</>
	)
}



interface ModalEditMeterProps {
	meter?: MeterSetting
	onClose?: () => void;
}
export const ModalEditMeter = ({ meter, onClose }: ModalEditMeterProps) => {
	const [addMeterSetting, addResult] = useAddControllerMeterSettingMutation();
	const [deleteMeterSetting, deleteResult] = useDeleteControllerMeterSettingMutation();
	const [updateMeterSetting, updateResult] = useUpdateControllerMeterSettingMutation();
	const toast = useToast();

	if (!meter) {
		return null;
	}

	const deleteF = () => {
		deleteMeterSetting(meter);
		if (onClose) {
			onClose();
		}
	}

	return (
		<Formik
			initialValues={meter}
			onSubmit={(values) => {
				
				if (meter.id) {
					updateMeterSetting(values).unwrap().then((meter) => {
						toast({
							title: `Sparad!`,
							status: "success",
							isClosable: true,
						});
						if (onClose) {
							onClose();
						}

					});
					return;
				}
				addMeterSetting(values).unwrap().then(() => {
					toast({
						title: `Skapad!`,
						status: "success",
						isClosable: true,
					})
					if (onClose) {
						onClose();
					}
				});

			}}
		>
			{({ handleSubmit, errors, touched, values }) => (
				<form autoComplete="off" onSubmit={handleSubmit}>
					<FormControl isInvalid={!!errors.interfaceType && touched.interfaceType}>
						<FormLabel >Typ</FormLabel>
						<Field
							as={Select}
							id="interfaceType"
							name="interfaceType"
							validate={validateNotEmpty}
						>
							<option value="">Välj:</option>
							<option value="mbus">M-bus</option>
						</Field>
						<FormErrorMessage>{errors.interfaceType}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!errors.model && touched.model}>
						<FormLabel >Modell</FormLabel>
						<Field
							as={Select}
							id="model"
							name="model"
							validate={validateNotEmpty}
						>
							<option value="">Välj:</option>
							<option value="garo-GNM3D-MBUS">Garo GNM3D-MBUS elmätare</option>
						</Field>
						<FormErrorMessage>{errors.model}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!errors.position && touched.position}>
						<FormLabel >Vad mäter elmätaren</FormLabel>
						<Field
							as={Select}
							id="position"
							name="position"
							validate={validateNotEmpty}
						>
							<option value="">Välj:</option>
							<option value="heatpump_electric">Värmepumpen EL</option>
							<option value="heatpump_heat">Värmepumpen Tillverkad energi</option>
							<option value="districtHeating">Fjärrvärmen energi</option>
							<option value="building">Samtlig EL på nätabbonemanget</option>
						</Field>
						<FormErrorMessage>{errors.position}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!errors.primaryId && touched.primaryId}>
						<FormLabel >{values.interfaceType == "mbus" ? "Mbus primärt ID" : "ID"}</FormLabel>
						<Field
							autoFocus={true}
							data-lpignore='true'
							as={Input}
							name="primaryId"
						 validate={validateNumber}
						/>
						<FormErrorMessage>{errors.primaryId}</FormErrorMessage>
					</FormControl>

					<SimpleGrid minChildWidth='120px' spacing='5px'>
						{meter.id ? <Button mt={6} onClick={deleteF} colorScheme="red" width="200px">Ta bort</Button> : <div />}
						<Button mt={6} type="submit" colorScheme="teal" width="200px">Spara</Button>
					</SimpleGrid>
				</form>
			)}
		</Formik>

	)
}
const validateNotEmpty = (value: number | string) => {

	if (!value && value !== 0) {
		return "Måste fyllas i";
	}
}
const validateNumber = (value: number | string) => {

	if (isNaN(Number(value))) {
		return "Måste vara ett nummer";
	}
}
