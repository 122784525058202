import {
	Grid,
	GridItem,
	Heading,
	Box,
	BoxProps,
	Flex,
	FlexProps,
	useColorModeValue,
	Spinner,
	useDisclosure,
	Icon,
	Drawer,
	DrawerContent,
	IconButton,
	CloseButton,
	Tooltip,
	TableContainer,
	Table,
	Tr,
	Td,
	Tbody,
	useToast,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Button,
	Input,
	Select,
	SimpleGrid,
	Spacer,
	ButtonGroup,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from '@chakra-ui/react';
import {
	FiHome,
	FiTrendingUp,
	FiSettings,
	FiAlertCircle,
	FiMenu,
	FiRefreshCw,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { CheckCircleIcon, WarningIcon, QuestionIcon } from '@chakra-ui/icons';
import { useGetControllerAlarmsQuery, useGetControllerCurrentMetricsQuery, useGetControllerMetricsQuery, useGetControllerQuery, useUpdateControllerMutation } from '../../features/api/api'
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { ChildrenProps, Controller } from '../../types';
import { format, formatRelative, formatRFC3339, fromUnixTime, isValid, parse, parseJSON, sub } from 'date-fns';
import { sv } from 'date-fns/locale';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer, ReferenceArea, Bar, BarChart } from 'recharts';
import { scaleTime } from 'd3-scale';
import { useMemo, useState } from 'react';
import { PaginatedTable } from '../../features/table/table';



export const ControllerAlarmPage = () => {
	const formatTime = (time: string) => {
		if (!time) {
			return '';
		}
		const t = parseJSON(time);
		return <Tooltip label={format(t, 'yyyy-MM-dd HH:mm:ss')} fontSize='md'>{formatRelative(t, new Date(), { locale: sv })}</Tooltip>
	}
	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllerAlarmsQuery(id, { refetchOnMountOrArgChange: true });
	const cols = useMemo(
		() => [
			{
				header: "Tid",
				accessorKey: "time",
				cell: (info: any) => formatTime(info.getValue() ?? ''),
				size: 50
			},
			{
				header: "Larm",
				accessorKey: "message"
			},
			{
				header: "Återställt",
				accessorKey: "clearedTime",
				cell: (info: any) => formatTime(info.getValue() ?? '')
			}
		],
		[]
	);
	if (isLoading) {
		return <Spinner size='xl' />;
	}



	return (
		<ControllerPage >
			<TableContainer>
				<Heading as='h1' size='md' >
					Larm
				</Heading>

				<PaginatedTable __css={{ tableLayout: 'fixed', width: 'full' }} size='sm' columns={cols} data={data} />

			</TableContainer>
		</ControllerPage>
	)
}

const translations: Record<string, any> = {
	'indoor': {
		k: 'Inomhus',
		suffix: '°',
		color: '#ada011'
	},
	'outdoor': {
		k: 'Utomhus',
		suffix: '°',
		color: '#ED8936'
	},
	'heatCarrierForward': {
		k: 'Värmebärare',
		suffix: '°',
		color: '#FEB2B2'
	},
	'heatCarrierReturn': {
		k: 'Värmebärare retur',
		suffix: '°',
		color: '#F56565'
	},
	'radiatorForward': {
		k: 'Framledning',
		suffix: '°',
		color: '#299149'
	},
	'radiatorReturn': {
		k: 'Framledning retur',
		suffix: '°',
		color: '#38A169'
	},
	'brineIn': {
		k: 'Köldbärare in',
		suffix: '°',
		color: '#63B3ED'
	},
	'brineOut': {
		k: 'Köldbärare ut',
		suffix: '°',
		color: '#27719c'
	},
	'hotGasCompressor': {
		k: 'Hetgas',
		suffix: '°',
		color: '#C53030'
	},
	'warmWater': {
		k: 'Varmvatten',
		suffix: '°',
		color: '#DD6B20'
	},
	'compressor': {
		k: 'Compressor',
		suffix: '%',
		color: '#38A169'
	},
	'alarm': {
		k: 'Compressor',
		suffix: '%',
		color: '#00A3C4'
	},
	'switchValve': {
		k: 'Växelventil',
		suffix: '',
		color: '#C4F1F9'
	},
	'pumpBrine': {
		k: 'Köldbärarpump',
		suffix: '%',
		color: '#E9D8FD'
	},
	'pumpHeat': {
		k: 'Värmebärarpump',
		suffix: '%',
		color: '#805AD5'
	},
	'pumpRadiator': {
		k: 'Framledningspump',
		suffix: '%',
		color: '#FBB6CE'
	},
	//TODO svenska
	'superHeatTemperature': {
		k: 'superHeatTemperature',
		suffix: '°',
		color: '#0e3b62'
	},
	'suctionGasTemperature': {
		k: 'suctionGasTemperature',
		suffix: '°',
		color: '#33b91c'
	},
	'lowPressureSidePressure': {
		k: 'lowPressureSidePressure',
		suffix: 'bar(g)',
		color: '#e1115c'
	},
	'highPressureSidePressure': {
		k: 'highPressureSidePressure',
		suffix: 'bar(g)',
		color: '#903d91'
	},
	'heatingAllowed': {
		k: 'Värme tillåten',
		suffix: '',
		color: '#1ab02c',
		mapping: {
			0: 'nej',
			1: 'ja',
		}
	},
	'hotwaterAllowed': {
		k: 'Varmvatten tillåtet',
		suffix: '',
		color: '#b3cc37',
		mapping: {
			0: 'nej',
			1: 'ja',
		}
	}
}
interface GraphProps {
	data: any;
	setStart(d: Date): void;
	setStop(d: Date): void;
	translations: Record<string, any>;
	chartType: string;
	[x: string]: any;
}

export const Graph = ({ data, setStart, setStop, style, YtimeFormat, translations, chartType }: GraphProps) => {
	const columns = data?.length ? Object.keys(data[0]).filter(row => row != "time") : [];
	const [visibleColumns, setVisibleColumns] = useState<string[]>(columns);
	const [refArea, setRefArea] = useState({ left: '', right: '' });

	const zoom = () => {

		if (refArea.left === refArea.right || refArea.right === '') {
			setRefArea({ left: '', right: '' });
			return;
		}

		let left = refArea.left;
		let right = refArea.right;
		if (refArea.left > refArea.right) {
			left = refArea.right;
			right = refArea.left;
		}

		setStart(new Date(left));
		setStop(new Date(right));
		setRefArea({ left: '', right: '' });

	}

	const graphData = data.map((row: any) => {
		return {
			...row,
			time: parseJSON(row.time).valueOf() // to unixtimestamp
		}
	}) ?? [];


	const timeValues = graphData.map((row: any) => {
		return row.time;
	});
	// The d3 scaleTime domain requires numeric values
	const numericValues = timeValues.map((time: any) => {
		return time.valueOf();
	});
	// With .nice() we extend the domain nicely.
	const timeScale = scaleTime()
		.domain([Math.min(...numericValues), Math.max(...numericValues)])
		.nice();




	const tooltip = <RechartsTooltip
		labelFormatter={(value: number) => {
			return format((value), 'yyyy-MM-dd HH:mm:ss');
		}}
		formatter={(value, name, props) => {
			const key = props.dataKey ?? name;
			if (translations[key]?.mapping) {
				return translations[key].mapping[String(value)] ?? value;
			}
			if (translations[key]?.suffix) {
				return value + " " + translations[key]?.suffix;
			}
			return value;
		}}
	/>;

	const legend = <Legend onClick={(a, index, event) => {
		if (a.dataKey == undefined) {
			return;
		}
		const dataKey = a.dataKey.toString();
		if (event.ctrlKey == true) { // handle if we have ctrl+klick like grafana does it.
			if (a.inactive == true) {
				setVisibleColumns([...visibleColumns, dataKey]);
				return;
			}
			setVisibleColumns(visibleColumns.filter((column) => {
				return column != dataKey;
			}));
			return;
		}

		// if we are disabled and click on us we will enable only ourselves.
		if (a.inactive == true) {
			setVisibleColumns([dataKey]);
			return;
		}

		const diff = columns.filter(x => !visibleColumns.includes(x));

		if (diff.length === columns.length - 1) { // we had single selected and deselected that last one.
			setVisibleColumns([...columns]);
			return;
		}

		if (!a.inactive) {
			setVisibleColumns([dataKey]);
		}

	}}
	/>

	if (chartType == 'bar') {
		return (
			<div style={style}>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						onMouseDown={(e) => setRefArea({ left: e?.activeLabel ?? '', right: refArea.right })}
						onMouseMove={(e) => refArea.left && setRefArea({ right: e?.activeLabel ?? '', left: refArea.left })}
						onMouseUp={(e) => zoom()}
						data={graphData}
					>
						{tooltip}
						{legend}
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey="time"
							tickFormatter={(time: any) => {
								return format(parseJSON(time), YtimeFormat)
							}}
							//TODO we dont do any timescaling for bar chars at the moment. 
						/>
						<YAxis />
						{columns.map((column) => (
							<Bar
								// stackId={'a'} 
								name={translations[column]?.k ?? column}
								key={column}
								hide={!visibleColumns.includes(column)}
								type="monotone"
								dataKey={column}
								fill={translations[column]?.color ?? "#bf1953"}
							/>
						))}
						{refArea.left && refArea.right ? (
							<ReferenceArea x1={refArea.left} x2={refArea.right} strokeOpacity={0.3} />
						) : null}
					</BarChart>
				</ResponsiveContainer>
			</div>);

	}
	const xAxisArgs = {
		domain: timeScale.domain().map(date => date.valueOf()),
		scale: timeScale,
		xAxisType: 'number' as const,
		ticks: timeScale.ticks() as any,
		tickFormatter: (time: any) => {
			return format(parseJSON(time), YtimeFormat)
		}
	};

	return (
		<div style={style}>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					onMouseDown={(e) => setRefArea({ left: e?.activeLabel ?? '', right: refArea.right })}
					onMouseMove={(e) => refArea.left && setRefArea({ right: e?.activeLabel ?? '', left: refArea.left })}
					onMouseUp={(e) => zoom()}
					data={graphData}
				>
					{tooltip}
					{legend}
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="time"
						{...xAxisArgs}
					/>
					<YAxis />
					{columns.map((column) => (
						<Line name={translations[column]?.k ?? column} key={column} hide={!visibleColumns.includes(column)} dot={false} type="monotone" dataKey={column} stroke={translations[column]?.color ?? "#bf1953"} />
					))}
					{refArea.left && refArea.right ? (
						<ReferenceArea x1={refArea.left} x2={refArea.right} strokeOpacity={0.3} />
					) : null}
				</LineChart>
			</ResponsiveContainer>
		</div>);
}
/*
 *

 */

export const ControllerStatisticsPage = () => {
	const now = new Date();
	const { id } = useParams() as { id: string };
	const [timeFilter, setTimeFilter] = useState<string>("1h");
	const [startFormatted, setStartFormatted] = useState<string>(format(sub(now, { hours: 1 }), 'yyyy-MM-dd HH:mm:ss'));
	const [stopFormatted, setStopFormatted] = useState<string>(format(now, 'yyyy-MM-dd HH:mm:ss'));
	const [start, setStartRFC] = useState<string>(formatRFC3339(sub(now, { hours: 1 })));
	const [stop, setStopRFC] = useState<string>(formatRFC3339(now));
	const { data, error, isLoading, refetch } = useGetControllerMetricsQuery({ id: id, duration: timeFilter, stop: stop, start: start });

	const setStart = (d: Date) => {
		setStartFormatted(format(d, 'yyyy-MM-dd HH:mm:ss'));
		setStartRFC(formatRFC3339(d)) // send RFC to backend
	}
	const setStop = (d: Date) => {
		setStopFormatted(format(d, 'yyyy-MM-dd HH:mm:ss'));
		setStopRFC(formatRFC3339(d)) // send RFC to backend
	}

	const setDuration = (d: number) => {
		setTimeFilter(d + 'h');
		const now = new Date();
		setStart(sub(now, { hours: d }));
		setStop(now);
	}

	if (isLoading) {
		return <Spinner size='xl' />;
	}


	return (
		<ControllerPage  >
			<div>
				<Flex p={'10px'} minWidth='max-content' alignItems='center' gap='2'>
					<Box p='2'>
						<Heading size='sm'>Hämtas var 30d sekund</Heading>
					</Box>
					<Spacer />
					<Input width='300px' value={startFormatted} onChange={e => {
						setStartFormatted(e.target.value);
					}} onBlur={(e) => {
						const start = parse(e.target.value, 'yyyy-MM-dd HH:mm:ss', new Date())
						if (isValid(start)) {
							setStartRFC(formatRFC3339(start)) // do search on blur
						}
					}}
					/>
					<Input width='300px' value={stopFormatted} onChange={e => {
						setStopFormatted(e.target.value);
					}} onBlur={e => {
						const stop = parse(e.target.value, 'yyyy-MM-dd HH:mm:ss', new Date())
						if (isValid(stop)) {
							setStopRFC(formatRFC3339(stop)) // do search on blur
						}
					}} />
					<ButtonGroup gap='2'>
						<Button onClick={() => setDuration(720)} bg={timeFilter == '720h' ? 'gray.300' : 'gray.100'} >30d</Button>
						<Button onClick={() => setDuration(168)} bg={timeFilter == '168h' ? 'gray.300' : 'gray.100'} >7d</Button>
						<Button onClick={() => setDuration(24)} bg={timeFilter == '24h' ? 'gray.300' : 'gray.100'} >24t</Button>
						<Button onClick={() => setDuration(1)} bg={timeFilter == '1h' ? 'gray.300' : 'gray.100'} >1t</Button>
						{/*
						<IconButton
							variant="outline"
							onClick={refetch}
							aria-label="open menu"
							icon={<FiRefreshCw />}
						/>
*/}
					</ButtonGroup>
				</Flex>
				<Graph
					chartType='line'
					style={{ userSelect: 'none', height: "500px", width: '100%', paddingRight: '10px' }}
					translations={translations}
					data={data.data}
					YtimeFormat={'HH:mm'}
					setStart={setStart}
					setStop={setStop} />
				{/* TODO more graph elements here configureable?*/}
			</div>
		</ControllerPage>
	)
}
export const ControllerStatusPage = () => {
	const { id } = useParams() as { id: string };
	const { data, error, isLoading, refetch } = useGetControllerCurrentMetricsQuery(id);

	if (isLoading) {
		return <Spinner size='xl' />;
	}


	if (error) {
		return (<Alert status='error'>
			<AlertIcon />
			<AlertTitle>Misslyckades att ladda data!</AlertTitle>
			<AlertDescription>Prova igen eller kontakta supporten om problemet kvarstår.</AlertDescription>
		</Alert>
		);
	}


	const last = parseJSON(data?.last ?? '');
	return (
		<ControllerPage >
			<TableContainer>
				<Heading as='h1' size='md' >
					{format(last, 'yyyy-MM-dd HH:mm:ss')}
					<IconButton
						ml='10px'
						variant="outline"
						onClick={refetch}
						aria-label="open menu"
						icon={<FiRefreshCw />}
					/>
				</Heading>

				<Table w='400px' size='sm'>
					<Tbody>
						{Object.entries(data?.data ?? {}).map(([key, value]) => (
							<Tr key={key}>
								<Td>{translations[key]?.k ?? key}</Td>
								<Td isNumeric>{translations[key]?.mapping ? (translations[key]?.mapping[value] ?? value) : value}{translations[key]?.suffix ?? ''}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</ControllerPage >
	)
}



export const ControllerPage = ({ children }: ChildrenProps) => {
	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllerQuery(id);
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error", error);
		return null;
	}

	const online = data.online ? <Tooltip label='Online' fontSize='md'><Icon as={CheckCircleIcon} color="green.400" /></Tooltip> :
		<Tooltip label='Offline' fontSize='md'><Icon as={WarningIcon} color="red.400" /></Tooltip>
	return (
		<Grid
			templateAreas={`"header header tools"
                  "nav main main"`}
			gridTemplateRows={'120px 1fr 30px'}
			gridTemplateColumns={'180px 1fr'}
			//h='200px'
			gap='1'
			color='blackAlpha.700'
		>
			<GridItem
				p='25px'
				bg='gray.50'
				area={'header'}
			>
				<Heading as='h1' size='xl'  >
					{data.name} {online}
				</Heading>
				{data.location}
			</GridItem>
			<GridItem
				// bg='pink.300'
				area={'nav'}
				fontWeight='bold'
			>
				<SimpleSidebar linkItems={LinkItems}></SimpleSidebar>
			</GridItem>
			{/* <GridItem p='25px' bg='gray.50' area={'tools'}> */}
			{/* 	<Button */}
			{/* 		bg={'green.400'} */}
			{/* 		color={'white'} */}
			{/* 		rounded={'xl'} */}
			{/* 		boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'} */}
			{/* 		as={RouterLink} */}
			{/* 		to={'/inside/controller/' + data.id + '/settings'} */}
			{/* 		//TODO go to route /inside/controller/:id */}
			{/* 		_hover={{ */}
			{/* 			bg: 'green.500', */}
			{/* 		}} */}
			{/* 		_focus={{ */}
			{/* 			bg: 'green.500', */}
			{/* 		}}> */}
			{/* 		Redigera inställningar */}
			{/* 	</Button> */}
			{/* </GridItem> */}
			<GridItem area={'main'}>
				{children}
			</GridItem>
			{/* <GridItem pl='2' bg='blue.300' area={'footer'}> */}
			{/* 	Footer */}
			{/* </GridItem> */}
		</Grid>
	);
}

interface LinkItemProp {
	name: string;
	to: string;
	icon: IconType;
}

interface LinkItemProps {
	linkItems?: Array<LinkItemProp>;
}
const LinkItems: Array<LinkItemProp> = [
	{ name: 'Status', icon: FiHome, to: 'status' },
	{ name: 'Statistik', icon: FiTrendingUp, to: 'statistics' },
	// {name: 'Explore', icon: FiCompass },
	// {name: 'Favourites', icon: FiStar },
	{ name: 'Larm', icon: FiAlertCircle, to: 'alarms' },
	{ name: 'Inställningar', icon: FiSettings, to: 'settings' },
];

export function SimpleSidebar({ linkItems, ...rest }: LinkItemProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Box bg={useColorModeValue('gray.100', 'gray.900')}>
			<SidebarContent
				linkItems={linkItems}
				onClose={() => onClose}
				display={{ base: 'none', md: 'block' }}
				{...rest}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="sm">
				<DrawerContent>
					<SidebarContent linkItems={linkItems} onClose={onClose} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
		</Box>
	);
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
	linkItems?: Array<LinkItemProp>;
}

const SidebarContent = ({ linkItems, onClose, ...rest }: SidebarProps) => {
	const { id } = useParams() as { id: string };
	return (
		<Box
			pl="10px"
			pr="10px"
			bg={useColorModeValue('white', 'gray.900')}
			{...rest}>
			<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			{linkItems?.map((link) => (
				<NavItem as={RouterLink} key={link.name} icon={link.icon} to={'/inside/controller/' + id + '/' + link.to}>
					{link.name}
				</NavItem>
			))}
		</Box>
	);
};

interface NavItemProps extends FlexProps {
	icon: IconType;
	to: string;
	children: string;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
	return (
		<Flex
			align="center"
			p="10px"
			// mx="4"
			borderRadius="lg"
			role="group"
			cursor="pointer"
			_hover={{
				bg: 'teal.400',
				color: 'white',
			}}
			{...rest}>
			{icon && (
				<Icon
					mr="4"
					fontSize="16"
					_groupHover={{
						color: 'white',
					}}
					as={icon}
				/>
			)}
			{children}
		</Flex>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 24 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue('white', 'gray.900')}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
			justifyContent="flex-start"
			{...rest}>
			<IconButton
				variant="outline"
				onClick={onOpen}
				aria-label="open menu"
				icon={<FiMenu />}
			/>
		</Flex>
	);
};
