import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Table, TableContainer, Tbody, Td, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react"
import { Row } from "@tanstack/react-table";
import { Field, Formik } from "formik";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { NotificationSetting, User } from "../../types";
import { useAddControllerNotificationSettingMutation, useDeleteControllerNotificationSettingMutation, useGetControllerNotificationSettingsQuery, useGetUsersQuery, useUpdateControllerNotificationSettingMutation } from "../api/api";
import { PaginatedTable } from "../table/table"


export const NotificationSettingsTable = () => {

	const [addNotificationSetting, addResult] = useAddControllerNotificationSettingMutation();
	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllerNotificationSettingsQuery(id, { refetchOnMountOrArgChange: true });
	const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure();
	const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
	const [editingNotification, setEditingNotification] = useState<NotificationSetting | undefined>();
	const toast = useToast();
	const cols = useMemo(
		() => [
			{
				header: "Namn",
				accessorKey: "user.name",
				// cell: (row: any) => console.log(row.getValue()),
				size: 50
			},
			{
				header: "Filter",
				accessorKey: "alarmFilter"
			},
		],
		[]
	);

	if (isLoading) {
		return <Spinner size='xl' />;
	}

	const onRowClick = (row: Row<any>) => {
		setEditingNotification(row.original)
		onOpenEdit()
	}
	return (
		<>

			<Grid templateColumns='repeat(5, 1fr)' >
				<GridItem colSpan={3}>
					<Heading pb='20px' as='h1' size='md' >
						Notisinställningar
					</Heading>
				</GridItem>
				<GridItem colSpan={1}/>
				<GridItem colSpan={1} textAlign='right'>
					<Button
						onClick={onOpenAdd}
						bg={'teal.400'}
						color={'white'}
						rounded={'lg'}
						_hover={{
							bg: 'teal.500',
						}}
						_focus={{
							bg: 'teal.500',
						}}>
						Lägg till
					</Button>
				</GridItem>
			</Grid>
			<TableContainer>

				<Modal isOpen={isOpenAdd} onClose={onCloseAdd}>
					<ModalOverlay />
					<ModalContent minWidth="fit-content" height="fit-content">
						<ModalHeader>Lägg till användare</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<ModalUsersTable onClick={(user: User) => {
								addNotificationSetting({
									controllerId: id,
									userId: user.id,
									alarmFilter: '',
								}).unwrap().then(() => {
									toast({
										title: `${user.name} tillagd!`,
										status: "success",
										isClosable: true,
									})
								});
								onCloseAdd();
							}} />
						</ModalBody>

						<ModalFooter>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<Modal isOpen={isOpenEdit} onClose={onCloseEdit}>
					<ModalOverlay />
					<ModalContent minWidth="fit-content" height="fit-content">
						<ModalHeader>Ändra</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<ModalEditNotification onClose={onCloseEdit} notification={editingNotification} />
						</ModalBody>

						<ModalFooter>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<PaginatedTable onRowHover={{
					bg: 'teal.400',
					color: 'white',
					cursor: 'pointer',
				}} onRowClick={onRowClick} __css={{ tableLayout: 'fixed', width: 'full' }} size='sm' columns={cols} data={data} />

			</TableContainer>
		</>
	)
}



interface ModalEditNotificationProps {
	notification?: NotificationSetting
	onClose?: () => void;
}
export const ModalEditNotification = ({ notification, onClose }: ModalEditNotificationProps) => {
	const { id } = useParams() as { id: string };
	const [deleteNotificationSetting, deleteResult] = useDeleteControllerNotificationSettingMutation();
	const [updateNotificationSetting, updateResult] = useUpdateControllerNotificationSettingMutation();
	const { data, error, isLoading } = useGetUsersQuery();
	const toast = useToast();
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}
	if (!notification) {
		return null;
	}

	const deleteF = () => {
		deleteNotificationSetting(notification);
		if (onClose) {
			onClose();
		}
	}

	return (
		<Formik
			initialValues={notification}
			onSubmit={(values) => {
				updateNotificationSetting(values).unwrap().then((setting) => {
					toast({
						title: `Sparad!`,
						status: "success",
						isClosable: true,
					});
					if (onClose) {
						onClose();
					}

				});

			}}
		>
			{({ handleSubmit, errors, touched }) => (
				<form autoComplete="off" onSubmit={handleSubmit}>
					<FormControl isInvalid={!!errors.alarmFilter && touched.alarmFilter}>
						<FormLabel >Filter</FormLabel>
						<Field
							autoFocus={true}
							data-lpignore='true'
							as={Input}
							name="alarmFilter"
						// validate={validateNotEmpty}
						/>
						<FormErrorMessage>{errors.alarmFilter}</FormErrorMessage>
					</FormControl>

					<SimpleGrid minChildWidth='120px' spacing='5px'>
						<Button mt={6} onClick={deleteF} colorScheme="red" width="200px">Ta bort</Button>
						<Button mt={6} type="submit" colorScheme="teal" width="200px">Spara</Button>
					</SimpleGrid>
				</form>
			)}
		</Formik>

	)
}
interface UsersTableProps {
	onClick?: (user: User) => void;
}
export const ModalUsersTable = ({ onClick }: UsersTableProps) => {
	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetUsersQuery();
	const clickRow = (user: User) => {
		if (!onClick) {
			return;
		}
		onClick(user)
	};
	if (isLoading || !data) {
		return <Spinner size='xl' />;
	}

	if (error) {
		console.log("error is", error);
		return null;
	}

	return (

		<TableContainer>
			<Table size='sm'>
				<Thead>
					<Tr>
						<Td fontWeight="extrabold">Namn</Td>
						<Td fontWeight="extrabold">Användarnamn</Td>
						<Td fontWeight="extrabold">epost</Td>
					</Tr>
				</Thead>
				<Tbody>
					{data?.map((row) => (
						<Tr _hover={{
							bg: 'teal.400',
							color: 'white',
							cursor: 'pointer',
						}}
							onClick={() => clickRow(row)}
							key={row.id}
						>
							<Td>{row.name} </Td>
							<Td>{row.username} </Td>
							<Td>{row.email} </Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	)
}
