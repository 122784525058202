import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
// import { createStandaloneToast } from '@chakra-ui/react'
import { logout } from '../features/user/userSlice';



/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		if (isRejectedWithValue(action)) {
			console.warn('We got a rejected action!', action);
			if (action.payload.status === 401) {
				// logout so user get to needs to login error page when we get a 401
				api.dispatch(logout());
			}
		}
		return next(action);
	}
