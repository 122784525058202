import {
	Box,
	Flex,
	Text,
	IconButton,
	Button,
	Stack,
	Collapse,
	Link,
	useColorModeValue,
	useDisclosure,
	Image,
	Heading,
	useToast,
	Center,
	ToastProviderProps,
	CreateToastFnReturn,
	PopoverTrigger,
	Popover,
	PopoverContent,
	Icon,
} from '@chakra-ui/react';
import {
	HamburgerIcon,
	CloseIcon,
	ChevronRightIcon,
	ChevronDownIcon,
} from '@chakra-ui/icons';
import { ColorModeSwitcher } from "../../ColorModeSwitcher"
import logo from '../../logo-small.svg';
import { Link as RouterLink } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { logout } from '../../features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { checkRight } from '../../features/user/userSlice'
import { lightFormat } from 'date-fns';




const LoginButton = () => {
	const userID = useAppSelector((state) => state.user.id)
	const toast = useToast()
	const dispatch = useAppDispatch();
	const navigate = useNavigate()
	const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		const response = await fetch('/api/logout-v1', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
		});
		if (!response.ok) {
			toast({
				title: `Utloggning misslyckades.`,
				status: "error",
				isClosable: true,
			})
			throw new Error("login failed");
		}
		dispatch(logout());
		navigate('/login');
		toast({
			title: `Du är nu utloggad`,
			status: "success",
			isClosable: true,
		})
	}

	if (userID) {
		return (
			<Button
				fontSize={'sm'}
				fontWeight={400}
				variant={'link'}
				onClick={onClick}
			>
				Logga ut
			</Button>
		)
	}
	return (
		<Button
			as={RouterLink}
			fontSize={'sm'}
			fontWeight={400}
			variant={'link'}
			to='/login'>
			Logga in
		</Button>
	)
}

export const NavMenu = () => {
	const { isOpen, onToggle } = useDisclosure();

	const userID = useAppSelector((state) => state.user.id)

	const register = <Button
		display={{ base: 'none', md: 'inline-flex' }}
		fontSize={'sm'}
		fontWeight={600}
		color={'white'}
		bg={'teal.400'}
		as={RouterLink}
		to='/register'
		_hover={{
			bg: 'teal.500',
		}}>
		Skaffa
	</Button>

	return (
		<Box>
			<Flex
				bg={useColorModeValue('white', 'gray.800')}
				color={useColorModeValue('gray.600', 'white')}
				minH={'60px'}
				py={{ base: 2 }}
				px={{ base: 4 }}
				borderBottom={1}
				borderStyle={'solid'}
				borderColor={useColorModeValue('gray.200', 'gray.900')}
				align={'center'}>
				<Flex
					flex={{ base: 1, md: 'auto' }}
					ml={{ base: -2 }}
					display={{ base: 'flex', md: 'none' }}>
					<IconButton
						onClick={onToggle}
						icon={
							isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
						}
						variant={'ghost'}
						aria-label={'Toggle Navigation'}
					/>
				</Flex>
				<Link as={RouterLink} to='/'>
					<Image src={logo} />
				</Link>
				<Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
					<Flex display={{ base: 'none', md: 'flex' }} ml={10}>
						<DesktopNav />
					</Flex>
				</Flex>

				<Stack
					flex={{ base: 1, md: 0 }}
					justify={'flex-end'}
					direction={'row'}
					spacing={6}>
					<LoginButton />
					{userID ? '' : register}
					// <ColorModeSwitcher justifySelf="flex-end" /> TODO funkar rätt bra förutom login till vänster
				</Stack>
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav />
			</Collapse>
		</Box>
	);
}

const DesktopNav = () => {
	const linkColor = useColorModeValue('gray.600', 'gray.200');
	const linkHoverColor = useColorModeValue('gray.800', 'white');
	const popoverContentBgColor = useColorModeValue('white', 'gray.800')

	const user = useAppSelector((state) => state.user)

	const NAV_ITEMS = user.id ? [...NAV_ITEMS_INSIDE] : [...NAV_ITEMS_OUTSIDE];

	if (checkRight(user, "tenants.write")) {
		NAV_ITEMS.push({
			label: 'Admin',
			to: '/inside/admin/status',
		});
	}

	return (
		<Stack direction={'row'} spacing={4}>
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Popover trigger={'hover'} placement={'bottom-start'}>
						<Link
							as={RouterLink}
							to={navItem.to}
							p={2}
							fontSize={'sm'}
							fontWeight={500}
							color={linkColor}
							_hover={{
								textDecoration: 'underline',
								color: linkHoverColor,
							}}>
							<PopoverTrigger>
								<Heading size='sm'>{navItem.label}</Heading>
							</PopoverTrigger>
						</Link>

						{navItem.children && (
							<PopoverContent
								border={0}
								boxShadow={'xl'}
								bg={popoverContentBgColor}
								p={4}
								rounded={'xl'}
								minW={'sm'}>
								<Stack>
									{navItem.children.map((child) => (
										<DesktopSubNav key={child.label} {...child} />
									))}
								</Stack>
							</PopoverContent>
						)}
					</Popover>
				</Box>
			))}
		</Stack>
	);
};

const DesktopSubNav = ({ label, to, subLabel }: NavItem) => {
	return (
		<Box
			as={RouterLink}
			to={to}
			role={'group'}
			display={'block'}
			p={2}
			rounded={'md'}
			_hover={{ color: useColorModeValue('teal.600', 'teal.400'), textDecoration: 'underline' }}>
			<Stack direction={'row'} align={'center'}>
				<Box>
					<Text
						transition={'all .3s ease'}
						_groupHover={{ color: 'teal.400' }}
						fontWeight={500}>
						{label}
					</Text>
					<Text fontSize={'sm'}>{subLabel}</Text>
				</Box>
				<Flex
					transition={'all .3s ease'}
					opacity={0}
					_groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
					justify={'flex-end'}
					align={'center'}
					flex={1}>
					<Icon color={'teal.400'} w={5} h={5} as={ChevronRightIcon} />
				</Flex>
			</Stack>
		</Box>
	)
}


const MobileNav = () => {
	const userID = useAppSelector((state) => state.user.id)
	const NAV_ITEMS = userID ? NAV_ITEMS_INSIDE : NAV_ITEMS_OUTSIDE
	return (
		<Stack
			bg={useColorModeValue('white', 'gray.800')}
			p={4}
			display={{ md: 'none' }}>
			{NAV_ITEMS.map((navItem) => (
				<MobileNavItem key={navItem.label} {...navItem} />
			))}
		</Stack>
	);
};

const MobileNavItem = ({ label, to, children }: NavItem) => {
	const { isOpen, onToggle } = useDisclosure()

	return (
		<Stack spacing={4} onClick={children && onToggle} >
			<Flex
				py={2}
				as={RouterLink}
				to={to}
				justify={'space-between'}
				align={'center'}
				_hover={{
					textDecoration: 'none',
				}}>
				<Text
					fontWeight={600}
					color={useColorModeValue('gray.600', 'gray.200')}>
					{label}
				</Text>
				{children && (
					<Icon
						as={ChevronDownIcon}
						transition={'all .25s ease-in-out'}
						transform={isOpen ? 'rotate(180deg)' : ''}
						w={6}
						h={6}
					/>
				)}

			</Flex>
			<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
				<Stack
					mt={2}
					pl={4}
					borderLeft={1}
					borderStyle={'solid'}
					borderColor={useColorModeValue('gray.200', 'gray.700')}
					align={'start'}>
					{children &&
						children.map((child) => (
							<Box as={RouterLink} key={child.label} py={2} to={child.to}>
								{child.label}
							</Box>
						))}
				</Stack>
			</Collapse>
		</Stack>
	);
};

interface NavItem {
	label: string;
	subLabel?: string;
	to: string;
	children?: NavItem[];
}

const ABOUT_CHILDREN: Array<NavItem> = [
	{
		label: 'Hur funkar det',
		to: '/about/how',
	},
	{
		label: 'Priser',
		to: '/about/prices',
	},
	{
		label: 'Kontakt',
		to: '/about/contact',
	},
	{
		label: 'Personuppgiftspolicy',
		to: '/about/privacypolicy',
	},
];

const NAV_ITEMS_OUTSIDE: Array<NavItem> = [
	// {
	// 	label: 'Test',
	// 	to: '/test',
	// },
	// {
	// 	label: 'Företag',
	// 	href: '#',
	// },
	// {
	// 	label: 'Bostadsrättsföreningar',
	// 	href: '#',
	// },
	// {
	// 	label: 'Inspiration',
	// },
	{
		label: 'Beräkna din besparing',
		to: '/calculate',
	},
	{
		label: 'Om Nergy',
		to: '/about',
		children: ABOUT_CHILDREN,
	},

];
const NAV_ITEMS_INSIDE: Array<NavItem> = [
	{
		label: 'Översikt',
		to: '/inside/home',
	},
	{
		label: 'Inställningar',
		to: '/inside/settings',
	},
	{
		label: 'Om Nergy',
		to: '/about',
		children: ABOUT_CHILDREN,
	},
];


export const Footer = () => {
	return (
		<Box
			color={useColorModeValue('gray.700', 'gray.200')}>
			<Center
				borderTop={1}
				borderStyle={'solid'}
				borderColor={useColorModeValue('gray.200', 'gray.900')}
				py={4}>
				<Text>© {lightFormat(new Date(), 'yyyy')} Nergy teknik Sverige AB</Text>
			</Center>
		</Box>
	);
}
