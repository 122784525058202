import {
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useToast,
	useColorModeValue,
	Button,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from "../../app/hooks";
import { useState } from "react";

interface UsersTableProps {
	onSubmit?: () => void;
	userId: string;
}

const validatePassword = (values: string) => {
	let error = "";
	const passwordRegex = /(?=.*[0-9])/;
	if (!values) {
		error = "Lösenordet får inte vara tomt";
	} else if (values.length < 10) {
		error = "Lösenordet måste vara minst 10 tecken";
	} else if (!passwordRegex.test(values)) {
		error = "Lösenordet måste innehålla en siffra";
	}
	return error;
};


export const ResetPasswordForm = ({ onSubmit, userId }: UsersTableProps) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch();
	const toast = useToast()
	const [password, setPassword] = useState<string | null>();
	const [password2, setPassword2] = useState<string | null>();

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (password != password2) {
			toast({
				title: "lösenorden matchar inte",
				status: "error",
				isClosable: true,
			})
			return;
		}

		const err = validatePassword(password ?? '')
		if (err != "") {
			toast({
				title: err,
				status: "error",
				isClosable: true,
			})
			return;
		}


		const response = await fetch(`/api/admin/users-v1/${userId}/reset-password`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ userId: userId, password: password })
		});
		if (!response.ok) {
			toast({
				title: `Byte av lösenord misslyckades.`,
				status: "error",
				isClosable: true,
			})
			throw new Error("login failed");
		}
		let json = await response.json();

		toast({
			title: `Lösenordet bytt`,
			status: "success",
			isClosable: true,
		})
		if (onSubmit) {
			onSubmit();
		}
	}
	// TODO convert to Formik form with...
	//			<Formik
	// 	initialValues={initialValues}
	// 	onSubmit={(values) => {
	// 		if (isNew) {
	// 			addUser(values);
	// 			navigate('/inside/admin/users');
	// 			return;
	// 		}
	// 		updateUser(values);
	// 		navigate('/inside/admin/users');
	// 	}}
	// >
	// 	{({ handleSubmit, errors, touched }) => (
	return (
		<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
			<Box
				rounded={'lg'}
				bg={useColorModeValue('white', 'gray.700')}
			>
				<form onSubmit={handleSubmit}>
					<FormControl id="password">
						<FormLabel>Nytt lösenord</FormLabel>
						<Input type="password" onChange={e => setPassword(e.target.value)} />
					</FormControl>
					<FormControl id="password2">
						<FormLabel>Bekräfta lösenord</FormLabel>
						<Input type="password" onChange={e => setPassword2(e.target.value)} />
					</FormControl>
					<Stack spacing={5}>
						<Stack
							direction={{ base: 'column', sm: 'row' }}
							align={'start'}
							justify={'space-between'}>
						</Stack>
						<Button
							type='submit'
							bg={'teal.400'}
							color={'white'}
							_hover={{
								bg: 'teal.500',
							}}>
							Byt lösenord
						</Button>
					</Stack>
				</form>
			</Box>
		</Stack>
	);

}
