import {
	GridItem,
	Heading,
	Spinner,
	Icon,
	Tooltip,
	useToast,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Button,
	Input,
	Select,
	SimpleGrid,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Slider,
	SliderTrack,
	Box,
	SliderFilledTrack,
	SliderThumb,
	Spacer,
	ButtonGroup,
	Flex,
	IconButton,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, QuestionIcon } from '@chakra-ui/icons';
import { useGetControllerAlarmsQuery, useGetControllerCurrentMetricsQuery, useGetControllerMetricsQuery, useGetControllerQuery, useGetEnergyPricesQuery, useUpdateControllerMutation } from '../../features/api/api'
import { useNavigate, useParams } from 'react-router-dom';
import { ChildrenProps, Controller } from '../../types';
import { Field, FieldValidator, Formik, FormikErrors, FormikTouched, FormikValues, getIn } from 'formik';
import { NotificationSettingsTable } from '../../features/notificationsettings/notificationsettings';
import { ControllerPage } from './controller';
import { useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import {
	CartesianGrid,
	LineChart,
	ResponsiveContainer,
	XAxis,
	Legend,
	YAxis,
	Tooltip as RechartsTooltip,
	Line,
	ReferenceArea,
	ComposedChart,
	Area,
	ReferenceLine,
	Label
} from 'recharts';
import { format, parseJSON } from 'date-fns';
import { scaleTime } from 'd3-scale';
import { log } from 'console';
import { MeterSettingsTable } from '../../features/metersettings/metersettings';


const validateNotEmpty = (value: number | string) => {

	if (!value && value !== 0) {
		return "Kan inte vara tom";
	}
}

export const ControllerSettingsPage = () => {
	const { id } = useParams() as { id: string };
	const { data, error, isLoading } = useGetControllerQuery(id);

	const [updateController, updateResult] = useUpdateControllerMutation();
	const navigate = useNavigate();
	const toast = useToast()
	const initialValues = data ?? {
		id: "",
		online: false,
		lastUpdate: '',
		name: "",
		type: "",
		location: "",
		tenantId: "",
		config: {
			heatControlType: '',
			address: '',
			consideredCheap: 0,
			electricBasePrice: 0,
			hotWaterHours: 0,
			hotWaterNormalStartTemperature: 0,
		}
	} as Controller

	if (isLoading) {
		return <Spinner size='xl' />;
	}

	return (
		<ControllerPage >
			<>
				<Tabs colorScheme='teal'>
					<TabList>
						<Tab>Inställningar</Tab>
						<Tab>Smart prisstyrning</Tab>
						<Tab>Notiser</Tab>
						<Tab>Mätinsamling</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<Formik
								initialValues={initialValues}
								onSubmit={(values) => {
									updateController(values).unwrap().then((controller) => {
										toast({
											title: `${controller.name} sparad.`,
											status: "success",
											isClosable: true,
										})
									});
									//TODO navigate to newly created or to list?
								}}
							>
								{({ handleSubmit, errors, touched }) => (
									<form autoComplete="off" onSubmit={handleSubmit}>

										<SimpleGrid columns={4} columnGap={10}  >
											<GridItem colSpan={1}>
												<Heading as='h3' size='md' mb="20px">Generellt</Heading>
												<FormControl isInvalid={!!errors.name && touched.name}>
													<FormLabel >Namn</FormLabel>
													<Field
														data-lpignore='true'
														as={Input}
														id="name"
														name="name"
														validate={validateNotEmpty}
													/>
													<FormErrorMessage>{errors.name}</FormErrorMessage>
												</FormControl>

												<FormControl isInvalid={!!errors.location && touched.location}>
													<FormLabel >Plats</FormLabel>
													<Field
														as={Input}
														id="location"
														name="location"
														validate={validateNotEmpty}
													/>
													<FormErrorMessage>{errors.location}</FormErrorMessage>
												</FormControl>
												<FormControl isInvalid={!!errors.type && touched.type}>
													<FormLabel >Typ</FormLabel>
													<Field
														as={Input}
														id="type"
														name="type"
														validate={validateNotEmpty}
													/>
													<FormErrorMessage>{errors.type}</FormErrorMessage>
												</FormControl>
											</GridItem>
											<GridItem colSpan={1}>
												<Heading as='h3' size='md' mb="20px">Anslutning</Heading>
												<FormControl isInvalid={!!getIn(errors, 'config.heatControlType') && getIn(touched, 'config.heatControlType')}>
													<FormLabel >Typ av interface</FormLabel>
													<Field
														as={Select}
														id="config.heatControlType"
														name="config.heatControlType"
														validate={validateNotEmpty}
													>
														<option value="">Välj:</option>
														<option value="thermiagenesis">Thermia Genesis modbus TCP</option>
														<option value="hogforsgst">Högfors GST Fiksu</option>
														<option value="dummy">dummy(används vid testning)</option>
													</Field>
													<FormErrorMessage>{getIn(errors, 'config.heatControlType')}</FormErrorMessage>
												</FormControl>

												<FormControl isInvalid={!!getIn(errors, 'config.address') && getIn(touched, 'config.address')}>
													<FormLabel >Address</FormLabel>
													<Field
														as={Input}
														placeholder='ex 192.168.0.100:502'
														id="config.address"
														name="config.address"
														validate={validateNotEmpty}
													/>
													<FormErrorMessage>{getIn(errors, 'config.address')}</FormErrorMessage>
												</FormControl>
											</GridItem>
										</SimpleGrid>
										<Button mt={6} type="submit" colorScheme="teal" width="200px">Spara</Button>
									</form>
								)}
							</Formik>
						</TabPanel>
						<TabPanel>
							<Formik
								initialValues={initialValues}
								onSubmit={(values) => {
									updateController(values).unwrap().then((controller) => {
										toast({
											title: `${controller.name} sparad.`,
											status: "success",
											isClosable: true,
										})
									});
								}}
							>
								{({ handleSubmit, errors, touched, setFieldValue, values }) => (
									<form autoComplete="off" onSubmit={handleSubmit}>

										<SimpleGrid columns={5} columnGap={10}  >
											<GridItem colSpan={1}>
												<Heading as='h3' size='md' mb="20px">Priser</Heading>
												<FormControl isInvalid={!!getIn(errors, 'config.consideredCheap') && getIn(touched, 'config.consideredCheap')}>
													<FormLabel >Alltid billigt kr/kwh <Tooltip label='Om elpriset är under detta gör vi ingen smart styrning alls.' fontSize='md'><Icon as={QuestionIcon} color="green.400" />
														</Tooltip>
													</FormLabel>
													<Field
														data-lpignore='true'
														as={Input}
														id="config.consideredCheap"
														name="config.consideredCheap"
														validate={validateNotEmpty}
														type='number'
													/>
													<FormErrorMessage>{getIn(errors, 'config.consideredCheap')}</FormErrorMessage>
												</FormControl>
												<FormControl isInvalid={!!getIn(errors, 'config.electricBasePrice') && getIn(touched, 'config.electricBasePrice')}>
													<FormLabel >Fast elpris kr/kwh <Tooltip label='Elpriset du har fast per kwh utöver det rörliga priset. Tex överföringsavgift och skatt.' fontSize='md'><Icon as={QuestionIcon} color="green.400" />
														</Tooltip>
													</FormLabel>
													<Field
														data-lpignore='true'
														as={Input}
														id="config.electricBasePrice"
														name="config.electricBasePrice"
														validate={validateNotEmpty}
														type='number'
													/>
													<FormErrorMessage>{getIn(errors, 'config.electricBasePrice')}</FormErrorMessage>
												</FormControl>
												<FormControl isInvalid={!!getIn(errors, 'config.districtHeatingPrice') && getIn(touched, 'config.districtHeatingPrice')}>
													<FormLabel >Fjärrvärmepris/kwh <Tooltip label='Om man har en hybridlösning med både fjärrvärme och värmepump fyller man i detta fältet. Annars fyller man i 0 här.' fontSize='md'><Icon as={QuestionIcon} color="green.400" /></Tooltip></FormLabel>
													<Field
														as={Input}
														type='number'
														id="config.districtHeatingPrice"
														name="config.districtHeatingPrice"
														validate={validateNotEmpty}
													/>
													<FormErrorMessage>{getIn(errors, 'config.districtHeatingPrice')}</FormErrorMessage>
												</FormControl>
												<Heading as='h3' size='md' mt="20px" mb="20px">Varmvatten</Heading>
												<FormControl isInvalid={!!getIn(errors, 'config.hotWaterHours') && getIn(touched, 'config.hotWaterHours')}>
													<FormLabel >Extra varmvatten timmar per dygn</FormLabel>
													<Field
														as={Input}
														type='number'
														id="config.hotWaterHours"
														name="config.hotWaterHours"
														validate={validateNotEmpty}
													/>
													<FormErrorMessage>{getIn(errors, 'config.hotWaterHours')}</FormErrorMessage>
												</FormControl>

												<FormLabel pt={'8px'} >Extra varmvatten start/stop temp</FormLabel>
												<SimpleGrid columns={2} columnGap={3}>
													<GridItem colSpan={1}>
														<FormControl isInvalid={!!getIn(errors, 'config.hotWaterBoostStartTemperature') && getIn(touched, 'config.hotWaterBoostStartTemperature')}>
															<Field
																as={Input}
																type='number'
																id="config.hotWaterBoostStartTemperature"
																name="config.hotWaterBoostStartTemperature"
																validate={validateNotEmpty}
															/>
															<FormErrorMessage>{getIn(errors, 'config.hotWaterBoostStartTemperature')}</FormErrorMessage>
														</FormControl>
													</GridItem>
													<GridItem colSpan={1}>
														<FormControl isInvalid={!!getIn(errors, 'config.hotWaterBoostStopTemperature') && getIn(touched, 'config.hotWaterBoostStopTemperature')}>
															<Field
																as={Input}
																type='number'
																id="config.hotWaterBoostStopTemperature"
																name="config.hotWaterBoostStopTemperature"
																validate={validateNotEmpty}
															/>
															<FormErrorMessage>{getIn(errors, 'config.hotWaterBoostStopTemperature')}</FormErrorMessage>
														</FormControl>
													</GridItem>
												</SimpleGrid>
												<FormLabel pt={'8px'} >Normal start/stop temperatur</FormLabel>
												<SimpleGrid columns={2} columnGap={3}>
													<GridItem colSpan={1}>
														<FormControl isInvalid={!!getIn(errors, 'config.hotWaterNormalStartTemperature') && getIn(touched, 'config.hotWaterNormalStartTemperature')}>
															<Field
																as={Input}
																type='number'
																id="config.hotWaterNormalStartTemperature"
																name="config.hotWaterNormalStartTemperature"
																validate={validateNotEmpty}
															/>
															<FormErrorMessage>{getIn(errors, 'config.hotWaterNormalStartTemperature')}</FormErrorMessage>
														</FormControl>
													</GridItem>
													<GridItem colSpan={1}>
														<FormControl isInvalid={!!getIn(errors, 'config.hotWaterNormalStopTemperature') && getIn(touched, 'config.hotWaterNormalStopTemperature')}>
															<Field
																as={Input}
																type='number'
																id="config.hotWaterNormalStopTemperature"
																name="config.hotWaterNormalStopTemperature"
																validate={validateNotEmpty}
															/>
															<FormErrorMessage>{getIn(errors, 'config.hotWaterNormalStopTemperature')}</FormErrorMessage>
														</FormControl>
													</GridItem>
												</SimpleGrid>

												{/*
												<FormField errors={errors} touched={touched} name="config.hotWaterNormalStartTemperature" label="Normal start temp" />
												<FormField errors={errors} touched={touched} name="config.hotWaterNormalStopTemperature" label="Normal stop temp" />
												<FormField errors={errors} touched={touched} name="config.hotWaterBoostStartTemperature" label="Boost start temp" />
												<FormField errors={errors} touched={touched} name="config.hotWaterBoostStopTemperature" label="Boost stop temp" />
*/}

											</GridItem>
											<GridItem colSpan={1}>
												<Heading as='h3' size='md' mb="20px"></Heading>

												<FormControl pt={'100px'}>
													<FormLabel >Tillåten prisnivå varmvatten</FormLabel>
													<Slider
														onChange={(val) => setFieldValue("config.maxLevelHotwater", val)}
														name='config.maxLevelHotwater'
														id='config.maxLevelHotwater'
														value={values.config.maxLevelHotwater}
														min={0} max={10} step={1}>
														<SliderTrack bg='teal.100'>
															<Box position='relative' right={10} />
															<SliderFilledTrack bg='teal.500' />
														</SliderTrack>
														<SliderThumb boxSize={6} />
													</Slider>
												</FormControl>
												<FormControl pt={'100px'}>
													<FormLabel >Tillåten prinivå värme</FormLabel>
													<Slider
														onChange={(val) => setFieldValue("config.maxLevelHeating", val)}
														name='config.maxLevelHeating'
														id='config.maxLevelHeating'
														value={values.config.maxLevelHeating}
														min={0} max={10} step={1}>
														<SliderTrack bg='teal.100'>
															<Box position='relative' right={10} />
															<SliderFilledTrack bg='teal.500' />
														</SliderTrack>
														<SliderThumb boxSize={6} />
													</Slider>
												</FormControl>
											</GridItem>
											<GridItem colSpan={3}>
												<PriceGraph hotWaterHours={values.config.hotWaterHours} hotwaterMaxLevel={values.config.maxLevelHotwater} heatingMaxLevel={values.config.maxLevelHeating} />
											</GridItem>
										</SimpleGrid>
										<Button mt={6} type="submit" colorScheme="teal" width="200px">Spara</Button>
									</form>
								)}
							</Formik>

						</TabPanel>
						<TabPanel>
							<NotificationSettingsTable />
						</TabPanel>
						<TabPanel>
							<MeterSettingsTable />
						</TabPanel>
					</TabPanels>
				</Tabs>

			</>
		</ControllerPage >
	)
}
const translations: Record<string, any> = {
	'currency': {
		k: 'Inomhus',
		suffix: '°',
		color: '#FAF089'
	},
	'outdoor': {
		k: 'Utomhus',
		suffix: '°',
		color: '#ED8936'
	},
}


interface Field {
	name: string;
}

const FormField = ({ errors, touched, name, label, validate }: {
	errors: FormikErrors<FormikValues>,
	touched: FormikTouched<FormikValues>,
	name: string,
	label: string,
	validate?: FieldValidator
}) => {
	const errStr = String(getIn(errors, name));

	return (
		<FormControl pt={'8px'} isInvalid={!!getIn(errors, name) && getIn(touched, name)}>
			<FormLabel >{label}</FormLabel>
			<Field
				as={Input}
				type='number'
				id={name}
				name={name}
				validate={validate}
			/>
			<FormErrorMessage>{errStr}</FormErrorMessage>
		</FormControl>
	)

}

const PriceGraph = ({ heatingMaxLevel, hotwaterMaxLevel, hotWaterHours }: { heatingMaxLevel: number, hotwaterMaxLevel: number, hotWaterHours: number }) => {
	const { data, error, isLoading, refetch } = useGetEnergyPricesQuery();
	const [timeFilter, setTimeFilter] = useState<string>("24h");

	if (isLoading) {
		return <Spinner size='xl' />;
	}


	const sorted = structuredClone(data) ?? [];
	sorted.sort((a, b) => (a.price > b.price ? 1 : -1));
	const cheapest = sorted?.slice(0, hotWaterHours)

	const graphData = data?.map((row: any) => {
		return {
			...row,
			time: parseJSON(row.time).valueOf(), // to unixtimestamp
			heatingAllowed: row.level <= heatingMaxLevel,
			hotwaterAllowed: row.level <= hotwaterMaxLevel,
			hotwaterForce: cheapest.some((i) => i.time === row.time),
		}
	}) ?? [];

	// hack we need to att last fake hour
	graphData.push({ time: graphData[graphData.length - 1].time + 3600000 });


	const timeScale = scaleTime()
		.domain([graphData[0].time, graphData[graphData.length - 1].time])
		.nice();


	const xAxisArgs = {
		domain: timeScale.domain().map(date => date.valueOf()),
		scale: timeScale,
		xAxisType: 'number' as const,
		ticks: timeScale.ticks() as any,
		tickFormatter: (time: any) => {
			return format(parseJSON(time), 'HH:mm')
		}
	};
	return (
		<div>
			<Flex minWidth='max-content'>
				<Spacer />
				{/*
				<ButtonGroup gap='2'>
					<Button onClick={() => setTimeFilter("720h")} bg={timeFilter == '720h' ? 'gray.300' : 'gray.100'} >30d</Button>
					<Button onClick={() => setTimeFilter("168h")} bg={timeFilter == '168h' ? 'gray.300' : 'gray.100'} >7d</Button>
					<Button onClick={() => setTimeFilter("24h")} bg={timeFilter == '24h' ? 'gray.300' : 'gray.100'} >24t</Button>
					<IconButton
						variant="outline"
						onClick={refetch}
						aria-label="open menu"
						icon={<FiRefreshCw />}
					/>
				</ButtonGroup>*/}
			</Flex>
			<div style={{ userSelect: 'none', height: "500px", width: '100%' }}>
				<ResponsiveContainer width="100%" height="100%">
					<ComposedChart
						data={graphData}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey="time"
							{...xAxisArgs}
						/>

						<YAxis unit={' kr'} />
						<RechartsTooltip
							labelFormatter={(value: number) => {
								return format((value), 'yyyy-MM-dd HH:mm:ss');
							}}
						/>


						<Line name={"Pris"} key={"price"} dot={false} type="monotone" dataKey={"price"} stroke={"#319795"} />

						{
							graphData.map(({ time, heatingAllowed, hotwaterAllowed }) => (
								<>
									<ReferenceArea x1={time} x2={time + 3600000} shape={<ReferenceBandsHeating color={heatingAllowed ? '#2aa5a5' : '#A52A2A'} />} >
										{false && <Label position={'insideBottom'} angle={-90}>Värme </Label>}
									</ReferenceArea>
									<ReferenceArea x1={time} x2={time + 3600000} shape={<ReferenceBandsHotwater color={hotwaterAllowed ? '#2aa5a5' : '#A52A2A'} />} >
										{false && <Label position={'insideLeft'} angle={-90}>Varmvatten </Label>}
									</ReferenceArea>
								</>
							))}
						{
							graphData.map(({ time, hotwaterForce }) => {
								if (!hotwaterForce) {
									return null;
								}
								return (
									<>
										<ReferenceArea x1={time} x2={time + 3600000} shape={<ReferenceBandsHotwaterForce color={'#acd918'} />} >
											<Label angle={-90}>Extra varmvatten </Label>
										</ReferenceArea>
									</>
								)
							})}


					</ComposedChart>
				</ResponsiveContainer>
			</div>
		</div>


	);
}

const ReferenceBandsHotwaterForce = (props: any) => {
	const { x, height, y, width, color, key } = props;

	return (
		<g>
			<rect
				key={key}
				x={x}
				y={y}
				width={width}
				height={height}
				fill={color}
				fillOpacity={0.3}
			/>
		</g>
	);
}

const ReferenceBandsHotwater = (props: any) => {
	const { x, height, y, width, color, key } = props;

	return (
		<g>
			<rect
				key={key}
				x={x}
				y={y}
				width={width}
				height={height / 2}
				fill={color}
				fillOpacity={0.3}
			/>
		</g>
	);
}
const ReferenceBandsHeating = (props: any) => {
	const { x, height, y, width, color, key } = props;

	return (
		<g>
			<rect
				key={key}
				x={x}
				y={y + height / 2}
				width={width}
				height={height / 2}
				fill={color}
				fillOpacity={0.3}
			/>
		</g>
	);
}
