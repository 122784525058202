import {
	GridItem,
	Heading,
	Box,
	useToast,
	Spinner,
	VStack,
	Button,
	FormControl,
	FormLabel,
	Input,
	SimpleGrid,
} from '@chakra-ui/react';
import { Tenant } from '../../types';
import { Formik, Field } from 'formik';
import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../features/api/api';

export const SettingsPage = () => {
	const settings = useGetSettingsQuery();
	const [updateSettings] = useUpdateSettingsMutation();
	const toast = useToast()

	if (settings.isLoading) {
		return <Spinner size='xl' />;
	}

	const initialValues = settings.data ?? {
		name: "",
		invoiceDetails: {
			address: "",
			city: "",
			postCode: "",
			name: ""
		},
	} as Tenant

	if (settings.error) {
		console.log("error is", settings.error);
		return null;
	}

	return (
		<Box bg="white" p={6} rounded="md" >
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					updateSettings(values).unwrap().then((settings) => {
						toast({
							title: `Inställningar sparade`,
							status: "success",
							isClosable: true,
						})
					});
				}}
			>
				{({ handleSubmit, values }) => (
					<form onSubmit={handleSubmit}>
						<VStack spacing={4} align="flex-start">

							<SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
								<GridItem colSpan={1}>
									<Heading as='h3' size='md' mb="20px">{values.name}</Heading>
								</GridItem>
								<GridItem colSpan={1}>
									<Heading as='h3' size='md' mb="20px">Faktureringsuppgifter</Heading>
									<FormControl>
										<FormLabel >Namn</FormLabel>
										<Field
											as={Input}
											id="invoiceDetails.name"
											name="invoiceDetails.name"
										/>
									</FormControl>
									<FormControl>
										<FormLabel >Address</FormLabel>
										<Field
											as={Input}
											id="invoiceDetails.address"
											name="invoiceDetails.address"
										/>
									</FormControl>
									<FormControl>
										<FormLabel >Postnummer</FormLabel>
										<Field
											as={Input}
											id="name"
											name="invoiceDetails.postCode"
										/>
									</FormControl>
									<FormControl>
										<FormLabel >Stad</FormLabel>
										<Field
											as={Input}
											id="invoiceDetails.city"
											name="invoiceDetails.city"
										/>
									</FormControl>
									<FormControl>
										<FormLabel >Faktureringsintervall (månader)</FormLabel>
										<Field
											as={Input}
											type='number'
											id="invoiceDetails.interval"
											name="invoiceDetails.interval"
										/>
									</FormControl>

								</GridItem>


							</SimpleGrid>
						</VStack>
						<Button type="submit" colorScheme="teal" width="200px">
							Spara
						</Button>
					</form>
				)}
			</Formik>
		</Box>
	)
}
